<template>
  <Dialog :header="$t('save.title')" :modal="true" :dismissableMask="true" @hide="saveMessage = null"
          :visible="display" @update:visible="$emit('update:display', $event)" contentStyle="padding-bottom: 0;">
    <div id="input-table">
      <label :for="'projectName'">{{ $t('projectName') }}</label>
      <InputText id="projectName" type="text" v-model="projectName"/>
      <label :for="'location'">{{ $t('location') }}</label>
      <InputText id="location" type="text" v-model="location"/>

      <label :for="'reference'">{{ $t('reference') }}</label>
      <InputText id="reference" type="text" v-model="reference"/>
      <label :for="'date'">{{ $t('date') }}</label>
      <InputText id="date" type="text" v-model="date"/>

      <label :for="'company'">{{ $t('company') }}</label>
      <InputText id="company" type="text" v-model="company"/>
      <label :for="'designer'">{{ $t('designer') }}</label>
      <InputText id="designer" type="text" v-model="designer"/>

      <label :for="'telephone'">{{ $t('telephone') }}</label>
      <InputText id="telephone" type="text" v-model="telephone"/>
      <label :for="'email'">{{ $t('email') }}</label>
      <InputText id="email" type="text" v-model="email"/>

      <div style="grid-column: 1 / 3;">
        <div class="form-row">
          <label :for="'share'">{{ $t('save.share') }}</label>
          <Checkbox id="share" v-model="share" :binary="true"/>
        </div>
        <p>{{ $t('save.advice') }}</p>
        <p>
          {{ $t('save.urgent') }}
          <br/>
          <span>{{ $t('feedback.uk') }}: <a href="mailto:TS-UK@hauraton.com">TS-UK@hauraton.com</a></span>
          <br/>
          <span>{{ $t('feedback.europe&beyond') }}: <a href="mailto:TSE@hauraton.com">TSE@hauraton.com</a></span>
        </p>
      </div>
    </div>
    <template #footer>
      <div style="display: flex; align-items: center">
        <span style="margin-right: auto;">{{ saveMessage }}</span>
        <Button :label="$t('cancel')" icon="pi pi-times" @click="$emit('update:display', false)"/>
        <span style="display: inline-block;" v-tooltip.top="{value: saveReason, disabled: canSave}">
          <SplitButton :label="$t('save.button')" icon="pi pi-check" :model="saveOptions" :disabled="!canSave"
                       @click="save(false)" v-if="project.details.saveID"/>
          <Button :label="$t('save.button')" icon="pi pi-check" :disabled="!canSave" @click="save" v-else/>
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script>
import SplitButton from "primevue/splitbutton";

import * as Sentry from "@sentry/vue";
import {mapState} from "vuex";
import {request as fetch} from "@/auth";
import {BASE_URL} from "@/constants";

export default {
  name: "SaveProject",
  components: {SplitButton},
  props: ['display'],
  emits: ['update:display'],
  data() {
    const $t = this.$t;
    return {
      saveOptions: [{
        get label() {
          return $t('save.as-button');
        },
        icon: 'pi pi-save',
        disabled: () => !this.project.details.saveID,
        command: () => this.save(true),
      }],
      saving: false,
      saveMessage: null,
    };
  },
  computed: {
    ...mapState([
        'project'
    ]),
    projectName: {
      get() {
        return this.project.details.name;
      },
      set(value) {
        this.$store.commit('setProjectName', value);
      }
    },
    location: {
      get() {
        return this.project.details.location;
      },
      set(value) {
        this.$store.commit('setLocation', value);
      }
    },
    reference: {
      get() {
        return this.project.details.reference;
      },
      set(value) {
        this.$store.commit('setReference', value);
      }
    },
    date: {
      get() {
        return this.project.details.date;
      },
      set(value) {
        this.$store.commit('setDate', value);
      }
    },
    company: {
      get() {
        return this.project.details.company;
      },
      set(value) {
        this.$store.commit('setCompany', value);
      }
    },
    designer: {
      get() {
        return this.project.details.designer;
      },
      set(value) {
        this.$store.commit('setDesigner', value);
      }
    },
    telephone: {
      get() {
        return this.project.details.telephone;
      },
      set(value) {
        this.$store.commit('setTelephone', value);
      }
    },
    email: {
      get() {
        return this.project.details.email;
      },
      set(value) {
        this.$store.commit('setEmail', value);
      }
    },
    share: {
      get() {
        return this.project.sharedWithHauraton;
      },
      set(value) {
        this.$store.commit('setSharedWithHauraton', value);
      }
    },
    canSave() {
      //Make sure all runs have been calculated, and there is a name for the project
      return this.project.runs.every(run => run.isCalculated) && !!this.projectName && !/^\s*$/.test(this.projectName);
    },
    saveReason() {
      let reason = [];

      const runs = this.project.runs.filter(run => !run.isCalculated).map(run => run.name);
      if (runs.length > 0) {
        reason.push(this.$t('save.calculateRuns', {runs: runs.join(', ')}));
      }
      if (!this.projectName || /^\s*$/.test(this.projectName)) {
        reason.push(this.$t('save.needProjectName'));
      }

      return reason.join('\n');
    },
  },
  methods: {
    async save(createNew) {
      console.assert(this.canSave);
      this.saveMessage = null;

      let oldSaveIDs;
      if (createNew) {
        oldSaveIDs = {//Remember these in case saving doesn't work
          saveID: this.project.details.saveID,
          runSaveIDs: this.project.runs.map(run => run.saveID),
        };
        this.$store.commit('setSaveIDs', {
          saveID: undefined,
          runSaveIDs: Array(this.project.runs.length).fill(undefined),
        });
      }

      const response = await fetch(`${BASE_URL}/drainage/save/`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(this.project.getSavingData({
            share: this.share,
          })),
      });
      if (response.ok) {
        const data = await response.json();
        this.$store.commit('setSaveIDs', data);
        this.$emit('update:display', false);
      } else if (response.status === 401) {
        this.saveMessage = this.$t('save.expiredSession');
        if (oldSaveIDs) this.$store.commit('setSaveIDs', oldSaveIDs); //Put them back again
      } else {
        this.saveMessage = this.$t('save.failed');
        if (oldSaveIDs) this.$store.commit('setSaveIDs', oldSaveIDs); //Put them back again
        Sentry.captureMessage('Error during project save', {
          extra: {
            type: 'fetch',
            status: response.status,
            response: await response.text(),
            projectID: this.project.details.saveID,
          },
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#input-table {
  width: 750px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(20px, auto);
  gap: 10px 30px;
}

.p-inputtext {
  padding: 3px;
  font-size: 0.7rem;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.p-checkbox-disabled {
  //PrimeVue still has a pointer cursor for disabled checkboxes
  cursor: default;
}

p {
  font-size: 0.8rem;
  text-align: justify;
  text-justify: inter-word;
}

.p-dialog-footer .p-splitbutton ::v-deep(button) {
  margin-right: 0;

  &.p-button-icon-only {
    width: 2.357rem; //The footer default sets this to auto
  }
}
</style>