import materials from "@/assets/scss/materials.scss";

//Pycharm will know about the colours when this is fixed: https://youtrack.jetbrains.com/issue/WEB-41662
//Until then we'll just have to improvise to stop it complaining

// noinspection JSUnresolvedVariable
export const ConcretePavement = {
	name: "Concrete Pavement",
	translationKey: "concretePavement",
	value: 1.0,
	cssName: "concrete-pavement",
	colour: materials.concretePavement,
}; // noinspection JSUnresolvedVariable
export const ResinSurface = {
	"name": "Resin Surface",
	translationKey: "resinSurface",
	"value": 1.0,
	cssName: "resin-surface",
	colour: materials.resinSurface,
}; // noinspection JSUnresolvedVariable
export const CementJointsBlockPavers = {
	"name": "Block Pavers - Cement Joints",
	translationKey: "bpCement",
	"value": 1.0,
	cssName: "bp-cement",
	colour: materials.bpCement,
}; // noinspection JSUnresolvedVariable
export const Asphalt = {
	"name": "Asphalt",
	translationKey: "asphalt",
	"value": 0.9,
	cssName: "asphalt",
	colour: materials.asphalt,
};// noinspection JSUnresolvedVariable
export const SandFilledBlockPavers = {
	"name": "Block Pavers - Sand Filled",
	translationKey: "bpSand",
	"value": 0.6,
	cssName: "bp-sand",
	colour: materials.bpSand,
}; // noinspection JSUnresolvedVariable
export const Gravel = {
	"name": "Gravel",
	translationKey: "gravel",
	"value": 0.6,
	cssName: "gravel",
	colour: materials.gravel,
}; // noinspection JSUnresolvedVariable
export const SoftLandscape = {
	"name": "Soft Landscape",
	translationKey: "softLandscape",
	"value": 0.3,
	cssName: "soft-landscape",
	colour: materials.softLandscape,
}; // noinspection JSUnresolvedVariable
export const Custom = {
	"name": "Custom",
	translationKey: "custom",
	get value() {
		//Generate a random number [0, 1] to one decimal place
		return Math.round(Math.random() * 10) / 10;
	},
	cssName: "custom",
	colour: materials.custom,
};

export default [
	ConcretePavement,
	ResinSurface,
	CementJointsBlockPavers,
	Asphalt,
	SandFilledBlockPavers,
	Gravel,
	SoftLandscape,
	Custom,
];
