<template>
  <div id="main-wrapper">
    <div v-if="isUserAuthenticated" class="sub-wrapper">
      <project-column :isHauratonUser="canUseAdvancedMode" :displayCatchmentArea="isAdvancedMode"
                      @new-project="handleNewProject" @load-project="handleLoadProject"
                      @save-project="handleSaveProject"
                      @switch-display="switchMode" @set-action="selectedAction = $event"
                      @change-run="changeRun" @delete-run="deleteRun($event)"
                      @calculate="handleCalculate"
                      @add-run="addRun" @duplicate-run="duplicateRun($event)"
      />
      <preloader v-show="isCalculating"/>
      <HauratonUserTabs v-model:selectedAction="selectedAction"
                        :isAdvancedMode="isAdvancedMode"/>
      <ResetAccessoriesWarning v-model:display="displayAccessoryResetWarning"/>
      <SuperCriticalWarning v-model:display="displaySuperCriticalWarning"/>
      <save-project v-model:display="savingProject"/>
      <Dialog :header="$t('load.title')" :modal="true" :dismissableMask="true"
              v-model:visible="loadingProject">
        <load-project @load="loadProject($event)" @close="loadingProject = false"/>
      </Dialog>
      <ProjectLoadBar v-if="runsToLoad > 0" :total="runsToLoad" :done="runsLoaded"/>
      <AutoCutDialog v-model:show="displayAutoCutDialog"/>
      <error-dialog :displayErrorDialog="displayErrorDialog" :errorMessage="errorMessage"
                    @close-error-dialog="displayErrorDialog =false"></error-dialog>
    </div>
    <div v-else class="container">
      <log-in-form/>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import {mapGetters, mapMutations} from "vuex";
import AutoCutDialog from "./main_content/tabs_content/AutoCutDialog";
import ProjectColumn from './left_side_box/ProjectColumn.vue'
import Preloader from "@/components/Preloader";
import HauratonUserTabs from "./main_content/HauratonUserTabs";
import ErrorDialog from "./main_content/tabs_content/ErrorDialog";
import LogInForm from "./main_content/LogInForm";
import LoadProject from "@/components/main_content/LoadProject";
import ProjectLoadBar from "@/components/main_content/ProjectLoadBar";
import SaveProject from "@/components/main_content/SaveProject";
import ResetAccessoriesWarning from "@/components/main_content/ResetAccessoriesWarning";
import SuperCriticalWarning from "@/components/main_content/tabs_content/design_run/SuperCriticalWarning";

export default {
  name: 'MainWrapper',
  components: {
    AutoCutDialog,
    LoadProject,
    LogInForm,
    ErrorDialog,
    HauratonUserTabs,
    Preloader,
    ProjectColumn,
    ProjectLoadBar,
    ResetAccessoriesWarning,
    SaveProject,
    SuperCriticalWarning,
  },
  props: ['project'],
  data() {
    return {
      selectedAction: 'catchment',
      errorMessage: '',
      displayAccessoryResetWarning: false,
      displaySuperCriticalWarning: false,
      savingProject: false,
      loadingProject: false,
      runsToLoad: 0,
      runsLoaded: new Set(),
      displayErrorDialog: false,
      displayAutoCutDialog: false,
    }
  },
  provide() {
    return {
      doCalculate: this.handleCalculate,
      showSuperCriticalWarning: () => this.displaySuperCriticalWarning = true,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.shareID) {
      next(vm => {
        vm.loadProject({shareID: to.params.shareID});
      });
    } else if (to.params.supportID) {
      next(vm => vm.loadProject({supportID: to.params.supportID}));
    } else if (to.params.superID) {
      next(vm => vm.loadProject({superID: to.params.superID}));
    } else if (from.params.shareID || from.name === 'shared-project' || from.name === 'staff-project-view') {
      next(vm => vm.handleNewProject()); //Clear the design if leaving a shared project
    } else next(); //Don't forget to call next or the page won't load
  },
  beforeRouteUpdate(to) {
    if (to.params.shareID) {
      this.loadProject({shareID: to.params.shareID});
    } else if (to.params.supportID) {
      this.loadProject({supportID: to.params.supportID});
    } else if (to.params.superID) {
      this.loadProject({superID: to.params.superID});
    }
  },
  computed: {
    ...mapGetters([
      'tabIndex',
      'isUserAuthenticated',
      'canUseAdvancedMode',
    ]),
    ...mapGetters({
      isCalculating: 'getIsCalculating',
      isCalculated: 'getIsCalculated',
    }),
    isAdvancedMode: {
      get() {
        return this.$store.getters.isAdvancedMode;
      },
      set(value) {
        this.$store.commit('setAdvancedMode', value);
        if (value) {
          const accessories = this.$store.getters.getAccessories.length;
          if (accessories) {
            this.$store.commit('resetAccessories');
            this.displayAccessoryResetWarning = this.$store.getters.getAccessories.length !== accessories;
          }
        }
      }
    },
  },
  methods: {
    ...mapMutations([
        'setTabIndex',
    ]),
    loadProject(request) {
      this.loadingProject = false;
      this.$store.dispatch('loadProject', {
        request,
        beforeLoad: () => {
          this.selectedAction = 'catchment';
        },
        loadingCallback: (i, total) => {
          if (i === 0) {
            this.runsToLoad = total;
          } else {
            this.runsLoaded.add(i);
          }
        },
      }).then(() => {
        if (this.$store.getters.isSelectedRunCalculated) {//Just in case
          if (this.isAdvancedMode) {
            this.setTabIndex(0);
            this.selectedAction = 'results';
          } else {
            this.setTabIndex(1);
            this.selectedAction = 'catchment';
          }
        }
      }, async error => {
        if (process.env.NODE_ENV === 'development') console.error('Error loading project', error);
        const message = await error.text?.() ?? error;
        if (!error.skipSentry) Sentry.captureException(message); //Avoid eating legitimate exceptions
        this.errorMessage = `${this.$t('load.failed')}: ${message}`;
        this.displayErrorDialog = true;
      }).finally(() => {
        this.runsToLoad = 0;
        this.runsLoaded.clear();
      });
    },
    handleNewProject() {
      this.selectedAction = 'catchment';
      this.$store.commit('resetRuns');
    },
    handleLoadProject() {
      this.loadingProject = true;
    },
    handleSaveProject() {
      this.savingProject = true;
    },
    switchMode() {
      this.isAdvancedMode = !this.isAdvancedMode;
      this.setTabIndex(0);
      this.selectedAction = 'catchment'
    },
    changeRun(run) {
      const wasAdvancedMode = this.isAdvancedMode;
      const wasCalculated = this.isCalculated;
      this.$store.commit('setSelectedRun', {run: run})
      if (this.isAdvancedMode && !run.isCalculated) {
        if (wasCalculated && !['catchment', 'channels'].includes(this.selectedAction)) {
          //We're on advanced mode but haven't calculated the run yet, need to switch back to the right design pane
          this.selectedAction = 'catchment';
        } else {
          //Going from one non-calculated run to another, retain the design pane we're already on
          console.assert(['catchment', 'channels'].includes(this.selectedAction));
        }
      } else if (this.isAdvancedMode !== wasAdvancedMode) {
        if (wasAdvancedMode) {
          //Switching to normal mode
          if (!run.isCalculated) {
            //Run isn't calculated yet, need to go to the design tab
            this.setTabIndex(0);
          } else if (this.tabIndex > 0) {
            //Account for system selection tab, so the materials/documents tabs are 1 further on
            this.setTabIndex(this.tabIndex + 1);
          } else if (this.selectedAction === 'results') {
            //Design results is tab 1 rather than a pane of 0
            this.setTabIndex(1);
          }
        } else {
          //Switching to advanced mode
          if (this.tabIndex === 1) {
            //On the design results, switch to the results pane (on tab 0)
            this.setTabIndex(0);
            console.assert(run.isCalculated); //Advanced + not calculated should already have been handled above
            this.selectedAction = 'results';
          } else if (this.tabIndex > 1) {
            //No more system selection tab, so the materials/documents tabs are 1 back
            this.setTabIndex(this.tabIndex - 1);
          }
        }
      }
    },
    deleteRun(run) {
      this.$store.commit('deleteRun', run)
    },
    handleCalculate() {
      console.assert(!this.$store.getters.needsLoading || !!this.$store.getters.getLoading);

      if (this.$store.getters.isAdvancedMode && !this.$store.getters.usedChannelLengthCorrect) {
          this.displayAutoCutDialog = true;
          return false;
      }

      this.$store.dispatch('doCalculate')
          .then(() => {
            if (this.isAdvancedMode) {
              this.setTabIndex(0);
              this.selectedAction = 'results'
            } else {
              this.setTabIndex(1); // Switch tabs
              this.selectedAction = 'catchment'
            }
            if (this.$store.getters.showSuperCriticalWarning) this.displaySuperCriticalWarning = true;
          }, async (failedResponse) => {
            if (this.selectedAction === 'results') {
              this.selectedAction = 'catchment'; //Reset back from the results screen
            }

            if (failedResponse instanceof Error) {
              if (process.env.NODE_ENV === 'development') console.error(failedResponse);
              Sentry.captureException(failedResponse);
            }

            if (failedResponse.untranslatedMessage) {
              this.errorMessage = this.$t(failedResponse.untranslatedMessage);
              return this.displayErrorDialog = true
            } else if (failedResponse.message) {
              this.errorMessage = failedResponse.message
              return this.displayErrorDialog = true
            } else if (failedResponse.silentFail) {
              return this.displayErrorDialog = false;
            } else if (failedResponse.status === 401) {
              this.errorMessage = this.$t('expiredSession');
              return this.displayErrorDialog = true;
            } else if (failedResponse.headers.get('Content-Type')?.startsWith("application/json")) {
              const parsedResponse = await failedResponse.json()
              this.errorMessage = parsedResponse.error
              return this.displayErrorDialog = true
            } else {
              this.errorMessage = await failedResponse.text();
              return this.displayErrorDialog = true
            }
          })
    },
    addRun() {
      this.$store.commit('addRun')
      this.selectedAction = 'catchment';
    },
    duplicateRun() {
      this.$store.commit('duplicateRun', {id: this.$store.getters.selectedRunId});
    },
  },
  mounted() {
    this.$store.commit('resetSelectedRun')
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#main-wrapper {
  /*border: 1px solid red;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.sub-wrapper {
  position: relative;
  width: 100%;
  display: flex;
}

.container {
  width: 100%;
  height: 100%;
}

@media (max-width: 1900px) {
  #main-wrapper {
  }
}
</style>
