<template>
  <div id="help">
    <div id="scroll-list">
      <div id="scroll-header" class="wrapper">
        <p class="scroll-header-text link" @click="selectedHeader = 'topics'">{{ $t('help.topicsTitle') }}</p>
        <!--
        <p class="scroll-header-text">|</p>
        <p class="scroll-header-text link" @click="selectedHeader = 'videos'">{{ $t('help.videoTutorials') }}</p>
        -->
      </div>
      <DataTable :value="selectedHeader === 'topics' ? helpTopics : videoTutorials"
                 :loading="loading" v-model:selection="selectedContent" selectionMode="single"
                 :scrollable="true" style="overflow: hidden" scrollHeight="flex">
        <Column column-key="topic_title">
          <template #body="{data}">
            {{ $t(`help.topic.${data.topic_file}`) }}
          </template>
        </Column>
      </DataTable>
    </div>
    <div id="main-content">
      <h3 v-show="selectedContent.topic_file">{{ $t(`help.topic.${selectedContent['topic_file']}`) }}</h3>
      <div v-if="selectedContent['help_type'] === 'T'" v-html="selectedHtml" class="html-content"/>
      <!--
      <div v-else-if="selectedContent['help_type'] === 'V'" class="video-content">
        <video class="video" ref="video" controls :src=selectedMp4 type="video/mp4">
        </video>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import {BASE_URL} from "@/constants";
import {storedUserProfile} from "@/auth";

export default {
  name: "HelpPage",
  data() {
    return {
      helpTopics: [],
      videoTutorials: [],
      selectedHeader: 'topics',
      selectedContent: {},
      loading: true,
      selectedHtml: '',
      selectedMp4: '',
    }
  },
  methods: {
    async getHelpTopics() {
      this.loading = true
      await fetch(`${BASE_URL}/drainage/help-topics`)
          .then(response => response.json())
          .then(data => {
            data.forEach(d => {
              if (d['help_type'] === 'V') {
                this.videoTutorials.push(d)
              } else {
                this.helpTopics.push(d)
              }
            })
            this.selectedContent = this.helpTopics[0];
            this.loading = false
          })
    }
  },
  watch: {
    selectedContent: async function () {
      if (this.selectedContent['help_type'] === 'T') {
        let languageFolder
        switch (this.$i18n.locale) {
          case 'en':
            languageFolder = 'en'
            break
          case 'de':
            languageFolder = 'de'
            break
        }
        const htmlFileName = this.selectedContent['topic_file']
        const helpPage = await fetch(`${BASE_URL}/static/help/${languageFolder}/${htmlFileName}.html`);
        this.selectedHtml = await helpPage.text();
      } else {
        if (this.selectedContent['help_type'] === 'V') {
          this.selectedMp4 = `${BASE_URL}/static/help/videos/${this.selectedContent['topic_file']}`;
        }
      }
    }
  },
  computed: {
    videoElement() {
      return this.$refs.video;
    },
  },
  mounted() {
    const user = storedUserProfile();
    if (user) {
      this.$store.commit('setUserDetails', user);
      this.$i18n.locale = user.language ?? 'en';
    }
    this.getHelpTopics()
  }
}
</script>

<style scoped>
#help {
  display: flex;
  /* Remaining page height after the header */
  height: calc(100vh - 110px);
}

#scroll-list {
  width: 20vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

#main-content {
  display: flex;
  flex-direction: column;
  line-height: 1.4em;
  flex-grow: 1;
}

.wrapper {
  display: flex;
}

#scroll-header {
  background-color: #c31924;
  color: white;
  justify-content: space-evenly;
}

.scroll-header-text {
  margin: 0.7rem;
  align-items: center;
}

::v-deep(th) {
  display: none !important;
}

.link {
  cursor: pointer;
}

.html-content {
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
}

.video-content {
  width: fit-content;
  margin: auto;
}

h3 {
  margin: 1rem auto;
  text-align: center;
}

.video {
  height: 80vh;
}

</style>
