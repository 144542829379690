<template>
  <DraggableDiv id="inflow-details" :x-start="popupPosition.x" :y-start="popupPosition.y">
    <template #header>
      <div id="header">
        <h5>{{ $t('popups.inflowDetails') }}</h5>
        <Button v-show="showClose" icon="pi pi-times" @click="handleInflowCancel(true)"
                class="p-button-rounded p-button-text p-button-plain tiny-button"/>
      </div>
    </template>
    <template #default>
      <div class="flex clm">
        <input-number :placeholder="$t('popups.position')" suffix=" m" v-model="visualPosition"
                      mode="decimal" :min="0" :max="channelLength" :minFractionDigits="1" :maxFractionDigits="1"
                      @focus="$event.target.select()" class="align-right" :class="{'p-invalid': missingPosition}"/>
        <dropdown v-model="source" :options="sources" optionLabel="name" option-value="id"
                  :placeholder="$t('popups.source')"/>
        <input-number :placeholder="$t('popups.flowRate')" suffix=" l/s" class="align-right" v-model="flow_lps"
                      :class="{'p-invalid': flow_lpsError}" mode="decimal" :minFractionDigits="1"
                      :maxFractionDigits="2" @focus="$event.target.select()" v-if="!source"/>
        <dropdown v-model="selectedConnectionMethod" :options="connectionMethods" option-value="code"
                  :optionLabel="({name}) => $t(`popups.${name}`)"
                  :optionDisabled="isConnectionDisabled"
                  :placeholder="$t('popups.connectionMethod')" :class="{'p-invalid': connectionMethodError}"/>
      </div>
      <div class="btn-wrap">
        <Button :label="$t('delete')" class="p-button-sm red" @click="handleInflowCancel(false)"/>
        <Button :label="$t('accept')" class="p-button-sm green" @click="handleAcceptClick"/>
      </div>
    </template>
  </DraggableDiv>
</template>

<script>
import DraggableDiv from "@/components/DraggableDiv";
import {mapGetters} from "vuex";

export default {
  name: "InflowPopup",
  components: {DraggableDiv},
  props: ['popupPosition', 'selectedInflowId'],
  emits: ['inflowCancel', 'inflow-accept'],
  data() {
    return {
      showClose: false,
      selectedConnectionMethod: '',
      connectionMethods: [
        {name: 'directPipe', code: 'direct-pipe'},
        {name: 'accessBox', code: 'access-box'},
        {name: 'concreteChamber', code: 'concrete-channel'},
      ],
      source: false,
      position: 0,
      missingPosition: false,
      flow_lps: null,
      flow_lpsError: false,
      connectionMethodError: false,
    }
  },
  mounted() {
    const inflowData = this.inflows.find(i => {
      return i.id === this.selectedInflowId;
    });
    console.assert(!!inflowData, `No inflow found for ${this.selectedInflowId}`);

    this.reset();
    this.position = inflowData.distance_m;
    if (!inflowData.toBeConfirmed || (inflowData.connection && inflowData.flow_lps)) {
      this.selectedConnectionMethod = inflowData.connection;
      this.source = inflowData.source ?? false;
      this.flow_lps = inflowData.flow_lps;
      this.showClose = true;
    } else {
      this.showClose = false; //Either accept or delete
    }
  },
  methods: {
    isConnectionDisabled(connection) {
      if (connection.code !== 'access-box') return false;
      //Knock the position back from the end of the channel (if it is there) so it is within the final channel
      const position_m = this.position === this.channelLength ? this.position - 0.1 : this.position;
      let distanceIn_m = this.selectedRun.hasDischargeCutting ? this.selectedRun.dischargeCutting.distance_in_mm / 1000 : 0;
      if (position_m < distanceIn_m) {//If the start of the channel is cut the profile length will reflect that
        const firstChannel = this.selectedRun.results.segments[0].channelDescription;
        return !this.selectedRun.results.available_accessories.available_boxes[firstChannel].includes(this.selectedRun.loading);
      }
      for (const segment of this.selectedRun.results.segments) {
        const segmentLength_m = segment.length_mm / 1000;
        if (distanceIn_m <= position_m && position_m < distanceIn_m + segmentLength_m) {
          return !this.selectedRun.results.available_accessories.available_boxes[segment.channelDescription].includes(this.selectedRun.loading);
        }
        distanceIn_m += segmentLength_m;
      }
      console.warn(`Point inflow at ${this.position}m fell out of profile (0 - ${this.channelLength}m)`);
      return false; //Fell out of the profile?
    },
    reset() {
      this.position = 0;
      this.missingPosition = false;
      this.source = false;
      this.selectedConnectionMethod = '';
      this.connectionMethodError = false;
      this.flow_lps = null;
      this.flow_lpsError = false;
    },
    handleInflowCancel(onlyClose) {
      this.reset();
      this.$emit('inflowCancel', onlyClose);
    },
    handleAcceptClick() {
      if (!this.flow_lps && !this.source) {
        return this.flow_lpsError = true
      }
      if (this.selectedConnectionMethod === '') {
        return this.connectionMethodError = true
      }
      if (this.position === null || this.position === undefined) {
        return this.missingPosition = true;
      }

      this.$emit('inflow-accept', {
        position: this.position,
        source: this.source || null,
        flow_lps: !this.source ? this.flow_lps : undefined,
        connection: this.selectedConnectionMethod
      })
      this.reset();
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters([
        'selectedRun',
        'runs',
        'isDischargeAtZero',
    ]),
    ...mapGetters({
      channelLength: 'getChannelLength',
      inflows: 'getInflows',
    }),
    visualPosition: {
      get() {
        return this.isDischargeAtZero ? this.position : this.channelLength - this.position;
      },
      set(position) {
        this.position = this.isDischargeAtZero ? position : this.channelLength - position;
      }
    },
    sources() {
      const runMap = new Map(this.runs.map(run => [run.id, run]));
      return [{
        name: this.$t('popups.independent'),
        id: false, //Can't be null or undefined as PrimeVue uses those interchangeably as empty/unselected
      }].concat(this.runs.filter(run => run.canFlowInto(this.selectedRun, runMap)).map(run => ({
        name: `${run.name} (${this.$n(run.results.channel_discharge ?? run.results.flow, {maximumFractionDigits: 2})} l/s)`,
        id: run.id,
      })));
    },
  }
}
</script>

<style scoped>
#inflow-details {
  z-index: 3;
  border-radius: 5px;
  height: fit-content;
  border: 1px solid lightgrey;
  background-color: white;
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h5 {
  margin: 0.2rem;
}

button.tiny-button {
  padding: 0;
  margin: 0;
  height: 1.5rem !important;
  width: 1.5rem;
}

.tiny-button > ::v-deep(*) {
  font-size: 0.8rem;
}

::v-deep(.p-inputtext) {
  font-size: 0.8rem;
  padding: 0.2rem;
  margin: 0.2rem;
}

::v-deep(.p-button-sm) {
  font-size: 0.8rem;
  padding: 0.1rem !important;
  width: 4rem;
  margin: 0.2rem;
}

::v-deep(.p-dropdown-label) {
  font-size: 0.8rem;
  padding: 0.1rem !important;
  width: 4rem;
}

::v-deep(.p-dropdown) {
  margin: 0.2rem;
}


.flex {
  display: flex;
}

.clm {
  flex-direction: column;
}

.btn-wrap {
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.align-right {
  text-align: right !important;
}

.error {
  color: red;
  font-size: 0.6rem;
  margin: 0;
  padding: 0 0 0 0.2rem;
  max-width: 10rem;
}

</style>
