import {BASE_URL} from "@/constants";

const SESSION_STORAGE_KEY = 'HauratonUserDetails';

export async function refresh() {
	//Short cut if there's no defaults stored we don't have a useful session to resurrect
	if (!window.localStorage.getItem(SESSION_STORAGE_KEY)) return false;

	const response = await fetch(`${BASE_URL}/auth/token/refresh/`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
	});
	//console.debug(await response.json());
	return response.ok;
}

export async function login(username, password) {
	const response = await fetch(`${BASE_URL}/auth/login/`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify({username, password}),
	});

	if (response.ok) {
		const data = await response.json();
		window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data.user));
		return {
			user: data.user,
		};
	} else if (response.status === 500) {
		return {
			message: "Server error, try again later",
		};
	} else {
		return false;
	}
}

export function storedUserProfile() {
	return JSON.parse(window.localStorage.getItem(SESSION_STORAGE_KEY));
}

export function updateUserProfile(userProfile) {
	const profile = storedUserProfile();
	Object.assign(profile, userProfile);
	window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(profile));
}

export async function request(url, init = {}) {
	let response = await fetch(url, init);

	//If the access token has expired, try refreshing it
	if (response.status === 401 && await refresh()) {
		response = fetch(url, init); //Refresh worked, make the original request again
	}

	return response;
}

export async function logout() {
    await fetch(`${BASE_URL}/auth/logout/`, {
        method: 'POST',
    });
	window.localStorage.removeItem(SESSION_STORAGE_KEY);
}
