<template>
  <div id="spinner">
    <i v-for="(style, i) in styles" :key="i" :style="style"></i>
  </div>
</template>

<script>
export default {
  name: "Preloader",
  data() {
    /** Number of raindrops to draw at once */
    const amount = 150;
    /** Background colours for the raindrops */
    const background = [
      //For more CSS blues: https://cssgradient.io/shades-of-blue/
      "linear-gradient(transparent, aqua)",
      "linear-gradient(transparent, cyan)",
      "linear-gradient(transparent, deepskyblue)",
      "linear-gradient(transparent, dodgerblue)",
    ];

    return {
      styles: Array.from({length: amount}, () => ({
        width: `${Math.random() * 5}px`,
        left: `${Math.floor(Math.random() * window.innerWidth)}px`,
        animationDelay:`${Math.random() * -20}s`,
        animationDuration: `${Math.random() * 10}s`,
        background: background[Math.floor(Math.random() * background.length)],
        opacity: Math.random() + 0.2,
      })),
    };
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize, {passive: true});
  },
  unmounted() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      for (const drop of this.styles) {
        drop.left = `${Math.floor(Math.random() * window.innerWidth)}px`;
      }
    }
  },
}
</script>

<style scoped>
#spinner {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

i {
  position: absolute;
  height: 120px;
  border-radius: 0 0 999px 999px;
  animation: rain 5s linear infinite;
}

@keyframes rain {
  0% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(calc(100vh + 120px));
  }
}

</style>
