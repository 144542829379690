import {createApp} from 'vue';
import App from './App.vue';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import {createI18n} from 'vue-i18n';
import PrimeVue from "primevue/config";
import ConfirmationService from 'primevue/confirmationservice';
import router from './router';
import 'primevue/resources/themes/saga-blue/theme.css';       //theme
import 'primevue/resources/primevue.min.css';                //core css
import 'primeicons/primeicons.css';                           //icons

import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Divider from 'primevue/divider';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Checkbox from 'primevue/checkbox';
import InputNumber from "@/components/InputNumber";
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
import Menu from 'primevue/menu';
import Tooltip from 'primevue/tooltip';
import {store} from "./store";

import en from './locales/en.json'
import de from './locales/de.json'
import fr from './locales/fr.json'
import hu from './locales/hu.json'
import it from './locales/it.json'
import hr from './locales/hr.json'


const i18n = createI18n({
    legacy: false,
    globalInjection: true, //Adds the $i18n, $t, etc. to all templates
    locale: 'en',
    fallbackLocale: 'en',
	silentFallbackWarn: false, //Set to true to skip falling back warnings (such as from de-DE to de)
    messages: {
        en,
        de,
		fr,
		hu,
		it,
		hr,
    }
});

const app = createApp(App);

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
		app,
		dsn: 'https://ffee4bd84a4329d6224bd26a191ee099@o4506710336208896.ingest.sentry.io/4506744440356864',
		environment: process.env.NODE_ENV,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
		],
		logErrors: process.env.NODE_ENV !== 'production',
		tracesSampleRate: 1.0,
		attachStacktrace: true,
		normalizeDepth: 5,
	});
}

app.use(PrimeVue, {
    locale: {
        //This covers supplying translations just for PrimeVue's own built in strings
        //The full list of which can be found here: https://primefaces.org/primevue/showcase/#/locale

        //That being said... until everything has switched over to Vue-i18n some improvising can be done
        ...Object.fromEntries(Object.entries(i18n.global.messages.value.en).map(([k, v]) => [k, v.source])),
    }
});

app.component('Button', Button);
app.component('Dropdown', Dropdown);
app.component('InputText', InputText);
app.component('TextArea', TextArea);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component("Divider", Divider);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("RadioButton", RadioButton);
app.component("Checkbox", Checkbox);
app.component("InputNumber", InputNumber);
app.component("Dialog", Dialog);
app.component("InputSwitch", InputSwitch);
app.component("SelectButton", SelectButton)
app.component("Menu", Menu)
app.directive("tooltip", Tooltip);
app.use(ConfirmationService);
app.use(i18n);
app.use(store);
app.use(router);
app.mount('#app');
