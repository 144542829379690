<template>
  <Dialog :header="$t('positionWarning.title')" :visible="displayPositionWarning" :style="{width: '600px'}">
    <p>{{ $t('positionWarning.text') }}</p>
    <template #footer>
      <Button :label="$t('ok')" icon="pi pi-check" autofocus @click="$emit('closePositionWarning')"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "ResetInflowsAndAccessoriesWarning",
  props: ['displayPositionWarning']
}
</script>

<style scoped>
</style>
