<template>
  <DraggableDiv id="accessory-details" :x-start="popupPosition.x" :y-start="popupPosition.y">
    <template #header>
      <div id="header">
        <h5>{{ $t('popups.accessoryDetails') }}</h5>
        <Button v-show="showClose" icon="pi pi-times" @click="handleCloseAccessory(true)"
                class="p-button-rounded p-button-text p-button-plain tiny-button"/>
      </div>
    </template>
    <template #default>
      <div class="flex clm">
        <input-number :placeholder="$t('popups.position')" class="align-right" suffix=" m" v-model="visualPosition"
                      mode="decimal" :min="0" :max="channelLength" :class="{'p-invalid': missingPosition}"
                      :minFractionDigits="1" :maxFractionDigits="1" @focus="$event.target.select()"/>
        <dropdown v-model="selectedType" :options="types" mode="decimal" :minFractionDigits="1" :maxFractionDigits="1"
                  :optionLabel="({name}) => $t(`popups.${name}`)" option-value="accessory_type"
                  :optionDisabled="isAccessoryDisabled" :placeholder="$t('popups.type')"
                  :class="{'p-invalid': missingType}"/>
      </div>
      <div class="btn-wrap">
        <Button :label="$t('delete')" class="p-button-sm red"
                @click="handleCloseAccessory(false)" :disabled="deleteDisabled"/>
        <Button :label="$t('accept')" class="p-button-sm green" @click="handleAcceptClick"/>
      </div>
    </template>
  </DraggableDiv>
</template>

<script>
import DraggableDiv from "@/components/DraggableDiv";
import {mapGetters} from "vuex";

const dischargeAccessories = [
    {accessory_type: 'trash-box', name: 'trashBox'},
    {accessory_type: 'access-box', name: 'accessBox'},
    {accessory_type: 'access-cover', name: 'accessCover'},
    {accessory_type: 'end-cap', name: 'endEap'},
    {accessory_type: 'end-cap-with-outlet', name: 'endEapWithOutlet'},
    {accessory_type: 'concrete-chamber', name: 'concreteChamber'},
];
const midChannelAccessories = [
    {accessory_type: 'access-box', name: 'accessBox'},
    {accessory_type: 'access-cover', name: 'accessCover'},
    {accessory_type: 'concrete-chamber', name: 'concreteChamber'},
    //{accessory_type: 'connection-plate', name: 'Connection Plate'},
];
const channelEndAccessories = [
    {accessory_type: 'access-box', name: 'accessBox'},
    {accessory_type: 'access-cover', name: 'accessCover'},
    {accessory_type: 'end-cap', name: 'endEap'},
    {accessory_type: 'concrete-chamber', name: 'concreteChamber'},
];

export default {
  name: "AccessoryPopup",
  components: {DraggableDiv},
  props: ['popupPosition', 'selectedAccessoryId'],
  emits: ['accessoryCancel', 'accessory-accept'],
  data() {
    return {
      showClose: false,
      selectedType: '',
      missingType: false,
      position: 0,
      missingPosition: false,
    }
  },
  mounted() {
    const accessoryData = this.accessories.find(i => {
      return i.id === this.selectedAccessoryId;
    });
    if (!accessoryData) {
      console.warn(`No accessory found for ${this.selectedAccessoryId}`);
      return this.handleCloseAccessory(false);
    }

    this.position = accessoryData.distance_m;
    if (!this.types.length) {//No accessories available in the current position
      if (this.position <= 1) {
        this.position = 0; //Snap to the discharge if we're close enough
      } else if (this.channelLength - 1 <= this.position) {
        this.position = this.channelLength; //Snap to the end if we're close enough
      } else {
        this.handleCloseAccessory(false, true); //Somewhere between, warn there's nothing to use
      }
    } else if (!accessoryData.toBeConfirmed) {
      this.selectedType = accessoryData.type;
      this.showClose = true;
    } else {
      this.showClose = false; //Either accept or delete
    }
  },
  methods: {
    isAccessoryDisabled(accessory) {
      switch (accessory.accessory_type) {
        case 'access-box':
        case 'trash-box': {
          //Knock the position back from the end of the channel (if it is there) so it is within the final channel
          const position_m = this.position === this.channelLength ? this.position - 0.1 : this.position;
          //If placed at the discharge of a pre-cut channel, a trashbox can't be used
          if (position_m === 0 && this.selectedRun.results.largest_channel.endsWith('L')) return true;
          let distanceIn_m = this.selectedRun.hasDischargeCutting ? this.selectedRun.dischargeCutting.distance_in_mm / 1000 : 0;
          if (position_m < distanceIn_m) {//If the start of the channel is cut the profile length will reflect that
            const firstChannel = this.selectedRun.results.segments[0].channelDescription;
            return !this.selectedRun.results.available_accessories.available_boxes[firstChannel].includes(this.selectedRun.loading);
          }
          for (const segment of this.selectedRun.results.segments) {
            const segmentLength_m = segment.length_mm / 1000;
            if (distanceIn_m <= position_m && position_m < distanceIn_m + segmentLength_m) {
              return !this.selectedRun.results.available_accessories.available_boxes[segment.channelDescription].includes(this.selectedRun.loading);
            }
            distanceIn_m += segmentLength_m;
          }
          console.warn(`Accessory at ${this.position}m fell out of profile (0 - ${this.channelLength}m)`);
          return false; //Fell out of the profile?
        }
        case 'end-cap-with-outlet': //BIG BLS channels only have normal end caps
          if (this.selectedRun.selectedSystem?.slug === 'faserfix-big-bls') return true;
          // eslint-disable-next-line no-fallthrough
        case 'end-cap':
          //If placed at the discharge of a pre-cut channel, endcaps can't be used
          if (this.position === 0 && this.selectedRun.results.largest_channel.endsWith('L')) return true;
          return this.selectedRun.selectedSystem?.product_type === 'M';
        default:
          return false;
      }
    },
    handleCloseAccessory(onlyClose, noneAvailable = false) {
      this.missingType = false
      this.selectedType = ''
      this.missingPosition = false;
      this.$emit('accessoryCancel', {onlyClose, noneAvailable});
    },
    handleAcceptClick() {
      // Make sure the type of connection has been specified
      if (!this.selectedType) {
        return this.missingType = true
      }
      if (this.position === null || this.position === undefined) {
        return this.missingPosition = true;
      }

      this.$emit('accessory-accept', {
        position: this.position,
        type: this.selectedType,
      });
      this.missingType = false
      this.selectedType = ''
      this.missingPosition = false;
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      'selectedRun',
      'isDischargeAtZero',
    ]),
    ...mapGetters({
      channelLength: 'getChannelLength',
      accessories: 'getAccessories',
    }),
    visualPosition: {
      get() {
        return this.isDischargeAtZero ? this.position : this.channelLength - this.position;
      },
      set(position) {
        this.position = this.isDischargeAtZero ? position : this.channelLength - position;
      }
    },
    types() {
      const {available_accessories} = this.selectedRun.results;
      if (this.position === 0) return dischargeAccessories.filter(accessory => {
        switch (accessory.accessory_type) {
          case 'trash-box':
            return true;
          case 'access-box':
            return false;
          case 'access-cover':
            return available_accessories.use_access_cover;
          default:
            return true;
        }
      });
      else if (this.position === this.channelLength) {
        const {grated} = this.selectedRun.selectedSystem;
        return channelEndAccessories.filter(accessory => {
          switch (accessory.accessory_type) {
            case 'access-box':
            case 'concrete-chamber':
              return !grated && !available_accessories.use_access_cover;
            case 'access-cover':
              return available_accessories.use_access_cover;
            default:
              return true;
          }
        });
      } else {
        return midChannelAccessories.filter(accessory => {
          switch (accessory.accessory_type) {
            case 'access-box':
              return available_accessories.use_access_box || available_accessories.use_trash_box;
            case 'concrete-chamber':
              return available_accessories.use_concrete_chamber;
            case 'access-cover':
              return available_accessories.use_access_cover;
            default:
              console.warn('Unexpected mid-channel accessory', accessory);
              return false;
          }
        });
      }
    },
    deleteDisabled() {
      switch (this.selectedType) {
        case 'access-box':
        case 'trash-box':
          return this.position === 0 && this.selectedRun.dischargeSetting === 'outflow-in-trashbox';
        case 'end-cap-with-outlet':
          return this.position === 0 && this.selectedRun.dischargeSetting === 'end-cap-with-outlet';
        default:
          return false;
      }
    },
  }
}
</script>

<style scoped>
#accessory-details {
  z-index: 3;
  border-radius: 5px;
  padding: 0.5rem;
  height: fit-content;
  border: 1px solid lightgrey;
  background-color: white;
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h5 {
  margin: 0.2rem;
}

button.tiny-button {
  padding: 0;
  margin: 0;
  height: 1.5rem !important;
  width: 1.5rem;
}

.tiny-button > ::v-deep(*) {
  font-size: 0.8rem;
}

::v-deep(.p-inputtext) {
  font-size: 0.8rem;
  padding: 0.2rem;
  margin: 0.2rem;
}

::v-deep(.p-button-sm) {
  font-size: 0.8rem;
  padding: 0.1rem !important;
  width: 4rem;
  margin: 0.2rem;
}

::v-deep(.p-dropdown-label) {
  font-size: 0.8rem;
  padding: 0.1rem !important;
}

::v-deep(.p-dropdown) {
  margin: 0.2rem;
}


.flex {
  display: flex;
}

.clm {
  flex-direction: column;
}

.btn-wrap {
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.align-right {
  text-align: right !important;
}

.error {
  color: red;
  font-size: 0.6rem;
  margin: 0;
  padding: 0 0 0 0.2rem;
  max-width: 10rem;
}
</style>
