<template>
  <div id="radio-buttons">
    <div class="radiobutton">
      <RadioButton name="action" value="catchment" class="radio-wrapper"
                   :model-value="selectedAction" @click="$emit('setAction', 'catchment')"
                   id="catchmentButton"/>
      <label :for="'catchmentButton'"
             class="radio-label">{{ $t('catchmentLabel') }}</label>
    </div>
    <div class="radiobutton">
      <RadioButton name="action" value="channels" class="radio-wrapper" :class="channelsError ? 'red' : 'transparent'"
                   :model-value="selectedAction" @click="$emit('setAction', 'channels')"
                   id="channelsButton"/>
      <label :for="'channelsButton'"
             class="radio-label">{{ $t('channelsLabel') }}</label>
    </div>
    <div class="radiobutton">
      <RadioButton name="action" value="results" class="radio-wrapper"
                   :model-value="selectedAction" @click="$emit('setAction', 'results')" :disabled=!isCalculated
                   id="resultsButton"/>
      <label :for="'resultsButton'" class="radio-label"
             :style="{color: isCalculated ? 'inherit' : 'lightgray'}">{{
          $t('resultsLabel')
        }}</label>
    </div>
  </div>
  <div class="main-wrapper" v-if="selectedAction === 'channels'">
    <channels/>
  </div>
  <div class="main-wrapper" v-else-if="selectedAction === 'catchment'">
    <catchment/>
  </div>
  <div class="main-wrapper" v-else-if="selectedAction === 'results'">
    <results/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Channels from "./channels/Channels"
import Catchment from "./catchment/Catchment"
import Results from "./results/Results"


export default {
  props: ['selectedAction', 'user'],
  emits: ['setAction'],
  components: {Channels, Catchment, Results},
  name: "DesignRun",
  computed: {
    ...mapGetters({
      isCalculated: 'getIsCalculated',
    }),
    channelsError() {
      return this.$store.getters.getErrors.lengthsNotMatching;
    },
  },
}
</script>

<style scoped>
.radiobutton {
  width: 115px;
  display:flex;
  align-items: center;
}

#radio-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0.5rem;
}

.radio-wrapper {
  width: fit-content;
  border-radius: 50%;
}

.radio-label {
  margin-left: 0.5rem;
  font-size: 0.7rem;
  font-weight: bold;
}

.main-wrapper {
  flex-grow: 1;
}
</style>
