<template>
  <template v-if="endcaps.length > 0">
    <h4>{{ $t('endcaps.choose') }}</h4>
    <div class="row radios">
      <div class="radiobutton" v-for="endCap in endcaps" :key="endCap.id">
        <RadioButton :id="`end-caps-${endCap.id}`" name="end-caps" :value="endCap.id" class="p-button-sm"
                     v-model="selectedEndCap"/>
        <label :for="`end-caps-${endCap.id}`" class="radio-label">{{ endCap.name }}</label>
      </div>
    </div>
  </template>
  <p v-else-if="dischargeChannel">{{ $t('endcaps.none', {channel: dischargeChannel.name}) }}</p>
</template>

<script>
import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";

export default {
  name: "Endcaps",
  data() {
    return {
      endcaps: [],
    };
  },
  computed: {
    dischargeChannel() {
      return this.$store.getters.getRunChannels.at(-1);
    },
    selectedEndCap: {
      get() {
        return this.$store.getters.outflowEndcap;
      },
      set(endcap) {
        this.$store.commit('setOutflowEndcap', endcap);
      }
    },
  },
  watch: {
    dischargeChannel: {
      immediate: true,
      async handler(channel) {
        if (!channel) return this.endcaps = [];
        const response = await fetch(`${BASE_URL}/drainage/endcap/with_outlet/?channel=${channel.slug}`);
        const endcaps = await response.json();

        this.endcaps = endcaps.map(endcap => ({
          id: endcap.id,
          name: this.$t('endcaps.withOutlet', {material: endcap.material, diameter: endcap.outlet_diameter_mm}),
        })).filter((endcap, i, endcaps) => endcaps.findIndex(e => e.name === endcap.name) === i);
      }
    },
  },
}
</script>

<style scoped>
h4 {
  margin: 1rem 0;
}

.radio-label {
  margin: 0.5rem;
}

.radiobutton {
  margin: 0 29px 0.5rem;
  width: fit-content;
}
</style>