<template>
  <Dialog :header="$t('notes')" :visible="displayNotesModal" :style="{width: '600px'}" @show="updateNotes">
    <TextArea v-model="text" :autoResize="true" :rows="5" autofocus/>
    <template #footer>
      <Button :label="$t('cancel')" icon="pi pi-times" @click="$emit('closeNotesDialog')"
              class="p-button-text"/>
      <Button :label="$t('confirm')" icon="pi pi-check" @click="handleConfirmNote" autofocus/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "NotesDialog",
  props: ['displayNotesModal'],
  data() {
    return {
      text: ''
    }
  },
  methods: {
    updateNotes() {
      this.text = this.$store.getters.getNotes;
    },
    handleConfirmNote() {
      this.$emit('closeNotesDialog')
      console.log(this.text)
      this.$store.commit('setNotes', this.text)
    }
  }
}
</script>

<style scoped>
textarea {
  width: 100%;
  margin-top: 0.2rem; /* Make space for the top of the focus shadow */
  resize: vertical;
}
</style>
