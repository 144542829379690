<template>
  <data-table :value=projects :loading="projects === null" :rowHover="true" @row-click="loadProject"
              sortMode="single" sortField="created_at" :sortOrder="-1" class="p-datatable-sm"
              paginator :rows="25" :rowsPerPageOptions="[25, 50, 100]"
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown PrevPageLink PageLinks NextPageLink"
              :currentPageReportTemplate="$t('sharedProjects.pagination')"
              :row-class="row => ({invalid: row?.is_empty})">
    <Column field="name" :header="$t('sharedProjects.name')" sortable/>
    <Column field="location" :header="$t('sharedProjects.location')" sortable/>
    <Column field="reference" :header="$t('sharedProjects.reference')" sortable/>
    <Column field="country" :header="$t('sharedProjects.country')" sortable/>
    <Column field="created_at" :header="$t('sharedProjects.date')" sortable style="width: 50px;">
      <template #body="{data}">{{ formatDate(data.created_at) }}</template>
    </Column>
    <Column field="created_by" :header="$t('sharedProjects.creator')" sortable/>
    <Column field="saved_by" :header="$t('sharedProjects.saver')" sortable/>
    <template #empty>
      <div style="text-align: center;">{{ $t('sharedProjects.noProjects') }}</div>
    </template>
  </data-table>
</template>

<script>
import {BASE_URL} from '@/constants';
import {request as fetch} from '@/auth';
import * as Sentry from '@sentry/vue';

export default {
  name: 'LoadProject',
  emits: ['close', 'load'],
  data() {
    return {
      projects: null,
    };
  },
  async mounted() {
    const createdBy = this.$route.query.creatorID;
    const response = await fetch(`${BASE_URL}/drainage/shared_projects/${createdBy ? `?creator=${createdBy}` : ''}`);
    if (response.ok) {
      const data = await response.json();
      for (const row of data) {
        row.created_at = new Date(row.created_at);
      }
      this.projects = data;
    } else {
      Sentry.captureMessage('Error loading shared projects', {
        extra: {
          status: response.status,
          response: await response.text(),
        },
      });
    }
  },
  methods: {
    /** @param {Date} value The date to format */
    formatDate(value) {
      return value.toLocaleDateString(/* local formatting */undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    loadProject({data: project}) {
      if (!project) return; //Placeholder row
      if (project.is_empty) return alert('No runs in project!');
      this.$router.push({name: 'shared-project', params: {supportID: project.id}});
    },
  },
};
</script>

<style lang="scss" scoped>
.p-datatable {
  width: 100%;

  ::v-deep(tr) {
    font-size: 0.8rem;

    @each $row, $modifier in (even: -1, odd: 1) {
      &.invalid:nth-child(#{$row}) {//var(--red), but 12.5% and 25% opacity
        background: repeating-linear-gradient($modifier * 45deg,
                rgba(183, 24, 46, 0.125) 0 0.5rem,
                rgba(183, 24, 46, 0.25) 0.5rem 1rem
        ) !important;
        cursor: unset !important;
      }
    }
  }

  &.p-datatable-hoverable-rows ::v-deep(.p-datatable-tbody) > tr:not(.p-highlight):hover {
    //Change the cursor when hovering only over the project rows (rather than the column headers too)
    cursor: pointer;
  }

  ::v-deep(.p-paginator) {
    //The pagination controls all have their own padding/margins already
    padding-left: 0;
    padding-right: 0;

    .p-paginator-current {
      //PrimeVue has a pointer cursor for the pagination text for some reason
      cursor: default;
    }

    .p-paginator-rpp-options {
      //Push the rows per page to the left (and other pagination controls to the right)
      margin-right: auto;
    }
  }
}
</style>