<template>
  <canvas ref="canvas" :width="canvasWidth_px" :height="canvasHeight_px" id="canvas"></canvas>
</template>

<script>
import {fabric} from "fabric";

/** The width the channel body will be drawn with */
const channelLength_px = 400;
/** The offset between the top of the canvas and everything but the channel dimension arrows */
const offSet = 0;
/** The offset between the top of the canvas and the top of the length from end is drawn */
const topMarkerY = 0;

export default {
  name: "CuttingAtLast",
  props: ['enabled', 'channel', 'distance_mm', 'angleOfCutting', 'switchValue', 'mirror'],
  data() {
    return {
      /** Total available width to draw on */
      canvasWidth_px: 500,
      /** Total available height to draw on */
      canvasHeight_px: 200,
      markerHeight_px: 30,
      channelTop_px: 30,
      channelLeft_px: 27,
    }
  },
  mounted() {
    const ref = this.$refs.canvas;
    this.canvas = new fabric.Canvas(ref)
    this.drawChannel()
    this.drawMarker()
    this.drawCutting()
  },
  methods: {
    drawCutting() {
      const cutWidth_px = (this.channelWidth_px) / Math.tan(this.angleOfCutting * (Math.PI / 180));
      const leftStart_px = this.channelLeft_px + (this.mirror ? this.lengthFromBeginning_px * 2 + cutWidth_px : channelLength_px);
      const x1 = leftStart_px - this.lengthFromBeginning_px
      const y1 = offSet + this.markerHeight_px + this.cutOriginOffSet
      const x2 = x1 - cutWidth_px
      let y2 = offSet + this.channelTop_px
      if (!this.switchValue) {
        y2 += this.channelWidth_px;
      }
      const cutBodyMain = new fabric.Line(
          [x1, y1, x2, y2],
          {
            stroke: this.stroke,
            id: 'to-delete',
            selectable: false,
          }
      )
      const cutBodyTop = new fabric.Line(
          [
            leftStart_px - this.lengthFromBeginning_px,
            topMarkerY + this.markerHeight_px + this.cutOriginOffSet,
            leftStart_px - this.lengthFromBeginning_px,
            topMarkerY + this.markerHeight_px + 10 + this.cutOriginOffSet,
          ],
          {
            stroke: this.stroke,
            id: 'to-delete',
            selectable: false,
          }
      )
      this.canvas.add(cutBodyMain)
      this.canvas.add(cutBodyTop)
    },
    drawChannel() {
      const channel = new fabric.Rect({
        left: this.channelLeft_px,
        top: this.channelTop_px + offSet,
        fill: 'transparent',
        stroke: this.stroke,
        width: channelLength_px,
        height: this.channelWidth_px,
        selectable: false,
        id: 'channel'
      })

      // LENGTH LABEL
      const lengthLabel = new fabric.Text(`${this.channel.channel_length_mm}`, {
        id: 'label',
        top: this.channelTop_px + this.channelWidth_px + 5,
        left: this.channelLeft_px + channelLength_px / 2,
        originX: 'center',
        fontSize: 13,
        selectable: false,
        fontStyle: 'sans-serif',
        stroke: this.stroke,
        objectCaching: false,
      })

      // left arrow
      const lengthLeftArrowTop = new fabric.Line([
        this.channelLeft_px, // x1,
        this.channelTop_px + this.channelWidth_px + 20, // y1
        this.channelLeft_px + 5, // x2,
        this.channelTop_px + this.channelWidth_px + 15, // y2
      ], {stroke: this.stroke, selectable: false})

      const lengthLeftArrowBottom = new fabric.Line([
        this.channelLeft_px, // x1,
        this.channelTop_px + this.channelWidth_px + 20, // y1
        this.channelLeft_px + 5, // x2,
        this.channelTop_px + this.channelWidth_px + 25, // y2
      ], {stroke: this.stroke, selectable: false})

      // right arrow
      const lengthRightArrowTop = new fabric.Line([
        this.channelLeft_px + channelLength_px, // x1,
        this.channelTop_px + this.channelWidth_px + 20, // y1
        this.channelLeft_px + channelLength_px - 5, // x2,
        this.channelTop_px + this.channelWidth_px + 15, // y2
      ], {stroke: this.stroke, selectable: false})

      const lengthRightArrowBottom = new fabric.Line([
        this.channelLeft_px + channelLength_px, // x1,
        this.channelTop_px + this.channelWidth_px + 20, // y1
        this.channelLeft_px + channelLength_px - 5, // x2,
        this.channelTop_px + this.channelWidth_px + 25, // y2
      ], {stroke: this.stroke, selectable: false})

      const lengthLabelLine = new fabric.Line([
        this.channelLeft_px, // x1
        this.channelTop_px + this.channelWidth_px + 20, // y1
        channelLength_px + this.channelLeft_px, // x2
        this.channelTop_px + this.channelWidth_px + 20], {stroke: this.stroke, selectable: false}) // y2

      // WIDTH LABEL
      const widthLabel = new fabric.Text(`${this.channel.outer_width_mm}`, {
        id: 'label',
        top: this.channelTop_px + (this.channelWidth_px / 2) + 10,
        left: 0,
        fontSize: 13,
        selectable: false,
        angle: 270,
        fontStyle: 'sans-serif',
        stroke: this.stroke,
      })

      const widthLabelLine = new fabric.Line([
        10 + 4,
        this.channelTop_px,
        10 + 4,
        this.channelTop_px + this.channelWidth_px
      ], {stroke: this.stroke, selectable: false})

      const topArrowLeft = new fabric.Line([
        10 + 4,
        this.channelTop_px,
        5 + 4,
        this.channelTop_px + 5
      ], {stroke: this.stroke, selectable: false})

      const topArrowRight = new fabric.Line([
        10 + 4,
        this.channelTop_px,
        15 + 4,
        this.channelTop_px + 5
      ], {stroke: this.stroke, selectable: false})

      const bottomArrowLeft = new fabric.Line([
        10 + 4,
        this.channelTop_px + this.channelWidth_px,
        5 + 4,
        this.channelTop_px + this.channelWidth_px - 5
      ], {stroke: this.stroke, selectable: false})

      const bottomArrowRight = new fabric.Line([
        10 + 4,
        this.channelTop_px + this.channelWidth_px,
        15 + 4,
        this.channelTop_px + +this.channelWidth_px - 5
      ], {stroke: this.stroke, selectable: false})
      this.canvas.add(channel)
      this.canvas.add(lengthLabel)
      this.canvas.add(lengthLeftArrowTop)
      this.canvas.add(lengthLeftArrowBottom)
      this.canvas.add(lengthRightArrowTop)
      this.canvas.add(lengthRightArrowBottom)
      this.canvas.add(lengthLabelLine)
      this.canvas.add(widthLabel)
      this.canvas.add(widthLabelLine)
      this.canvas.add(topArrowLeft)
      this.canvas.add(topArrowRight)
      this.canvas.add(bottomArrowLeft)
      this.canvas.add(bottomArrowRight)
    },
    drawMarker() {
      const leftStart_px = this.channelLeft_px + (this.mirror ? this.lengthFromBeginning_px : channelLength_px);
      const startMarkerX = leftStart_px - this.lengthFromBeginning_px
      const startMarker = new fabric.Line([startMarkerX, topMarkerY + offSet, startMarkerX, topMarkerY + offSet + this.markerHeight_px],
          {
            id: 'to-delete',
            stroke: this.stroke,
            strokeWidth: 1,
            selectable: false
          }
      )
      const endMarkerX = leftStart_px
      const endMarker = new fabric.Line([endMarkerX, topMarkerY + offSet, endMarkerX, topMarkerY + this.markerHeight_px + offSet],
          {
            id: 'to-delete',
            stroke: this.stroke,
            strokeWidth: 1,
            selectable: false,
          }
      )
      const leftArrowBody = new fabric.Line([
        startMarkerX - 30,
        topMarkerY + 15 + offSet,
        (startMarkerX - 30) + 30,
        topMarkerY + 15 + offSet,
      ], {
        id: 'to-delete',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: false
      })
      const leftArrowPointTop = new fabric.Line([
        startMarkerX - 15,
        topMarkerY + 10 + offSet,
        (startMarkerX - 30) + 30,
        topMarkerY + 15 + offSet
      ], {
        id: 'to-delete',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: false
      })
      const leftArrowPointBottom = new fabric.Line([
        startMarkerX - 15,
        topMarkerY + 20 + offSet,
        (startMarkerX - 30) + 30,
        topMarkerY + 15 + offSet
      ], {
        id: 'to-delete',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: false
      })
      const rightArrowBody = new fabric.Line([
        endMarkerX + 30,
        topMarkerY + 15 + offSet,
        (endMarkerX + 30) - 30,
        topMarkerY + 15 + offSet
      ], {
        id: 'to-delete',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: false
      })
      const rightArrowPointTop = new fabric.Line([
        endMarkerX + 15,
        topMarkerY + 10 + offSet,
        (endMarkerX + 30) - 30,
        topMarkerY + 15 + offSet
      ], {
        id: 'to-delete',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: false
      })
      const rightArrowPointBottom = new fabric.Line([
        endMarkerX + 15,
        topMarkerY + 20 + offSet,
        (endMarkerX + 30) - 30,
        topMarkerY + 15 + offSet
      ], {
        id: 'to-delete',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: false
      })
      const label = new fabric.Text(`${this.distance_mm}`, {
        id: 'to-delete',
        top: 5 + offSet,
        left: (startMarkerX + (endMarkerX - startMarkerX) / 2) - (this.lengthFromBeginning_px / 20) - 5,
        fontSize: 13,
        selectable: false,
        fontStyle: 'sans-serif',
        stroke: this.stroke,
      })
      this.canvas.add(label)
      this.canvas.add(leftArrowPointTop)
      this.canvas.add(leftArrowPointBottom)
      this.canvas.add(leftArrowBody)
      this.canvas.add(rightArrowPointTop)
      this.canvas.add(rightArrowPointBottom)
      this.canvas.add(rightArrowBody)
      this.canvas.add(startMarker)
      this.canvas.add(endMarker)
    },
    updateDrawing() {
      const allObjects = this.canvas.getObjects()
      allObjects.map(o => this.canvas.remove(o))
      this.drawChannel()
      this.drawMarker()
      this.drawCutting()
    }
  },
  computed: {
    stroke() {
      return this.enabled ? 'black' : 'lightgray'
    },
    cutOriginOffSet() {
      return this.switchValue === false ? 0 : this.channelWidth_px
    },
    scale() {
      const scale = channelLength_px / this.channel.channel_length_mm;
      return Math.min(scale, 140 / this.channelWidth_mm);
    },
    lengthFromBeginning_px() {
      return this.channel && this.distance_mm * this.scale;
    },
    channelWidth_mm() {
      return this.channel && this.channel.outer_width_mm
    },
    channelWidth_px() {
      return this.channel && this.channelWidth_mm * this.scale;
    }
  },
  watch: {
    lengthFromBeginning_px() {
      this.updateDrawing()
    },
    channelWidth_px() {
      this.updateDrawing()
    },
    angleOfCutting() {
      this.updateDrawing()
    },
    switchValue() {
      this.updateDrawing()
    },
    channel() {
      this.updateDrawing()
    },
    stroke() {
      this.updateDrawing()
    }
  }
}
</script>

<style scoped>
#canvas {
  /*border: 1px solid red*/
}
</style>
