<template>
  <Dialog :header="$t('userSettings.title')" @show="loadProfile"
          :visible="display" @update:visible="$emit('update:display', $event)">
    <main style="display: inline-block;">
    <div>{{ $t('userSettings.language') }}</div>
    <Dropdown v-model="$i18n.locale" :options="$i18n.availableLocales" :optionLabel="translateLanguage"
              @change="updateLanguage"
              style="width:100%; font-size: 0.7rem!important;"
              placeholder="Language"/>
    <template v-if="user.has_advanced_mode">
      <div>{{ $t('userSettings.defaultMode') }}</div>
      <SelectButton v-model="defaultMode" :options="modes" optionValue="value"
                    :optionLabel="option => $t(`userSettings.${option.name}`)"/>
    </template>
    <div>{{ $t('userSettings.userRainfallUnits') }}</div>
    <SelectButton v-model="userRainfallUnits" :options="units" optionLabel="name" />
    <div>{{ $t('userSettings.projectRainfallUnits') }}</div>
    <SelectButton v-model="projectRainfallUnits" :options="units" optionLabel="name" />
    <div><InputText v-model="company" @blur="syncProfile" readonly :placeholder="$t('company')"/></div>
    <div><InputText v-model="telephone" @blur="syncProfile" :placeholder="$t('telephone')"/></div>
    <label>{{ $t('address') }}</label>
    <div id="address">
      <TextArea rows="4" v-model="address" @blur="syncProfile" style="resize: vertical;"/>
      <InputText v-model="city" @blur="syncProfile" :placeholder="$t('city')"/>
      <InputText v-model="postcode" @blur="syncProfile" :placeholder="$t('postcode')"/>
    </div>
    <template v-if="this.user.can_change_country">
      <label>{{ $t('country') }}</label>
      <CountryDropdown :country="country" @update:country="syncCountry($event)"/>
    </template>
    </main>
    <template #footer>
      <Button :label="$t('ok')" icon="pi pi-check" @click="$emit('update:display', false)"/>
    </template>
  </Dialog>
</template>

<script>
import {rainfall} from "@/units";
import {request as fetch} from "@/auth";
import {BASE_URL} from "@/constants";
import CountryDropdown from "@/components/main_content/tabs_content/CountryDropdown";
import {mapState, mapMutations} from "vuex";

export default {
  name: "UserSettings",
  components: {CountryDropdown},
  props: ['display'],
  emits: ['update:display'],
  data() {
    return {
      modes: [{
        name: 'standardMode',
        value: false,
      }, {
        name: 'advancedMode',
        value: true,
      }],
      units: [...rainfall.values()],
      company: null,
      telephone: null,
      address: null,
      city: null,
      postcode: null,
      country: null,
    };
  },
  computed: {
    ...mapState([
        'user',
    ]),
    defaultMode: {
      get() {
        return this.user.default_advanced_mode;
      },
      async set(default_advanced_mode) {
        await this.updateUserSettings({
          default_advanced_mode,
        });
        this.updateUserDetails({
          default_advanced_mode,
        });
      }
    },
    userRainfallUnits: {
      get() {
        return this.user.rainfallUnits;
      },
      async set(rainfallUnits) {
        await this.updateUserSettings({
          rainfall_units: rainfallUnits.stored,
        });
        this.updateUserDetails({
          rainfallUnits,
        });
        this.projectRainfallUnits = rainfallUnits;
      }
    },
    projectRainfallUnits: {
      get() {
        return this.$store.state.project.rainfallUnits;
      },
      set(rainfallUnits) {
        this.$store.commit('setProjectDefaults', {
          rainfallUnits,
        });
      }
    },
  },
  methods: {
    ...mapMutations([
       'updateUserDetails',
    ]),
    loadProfile() {
      const profile = this.user;
      this.company = profile.company;
      this.telephone = profile.telephone;
      this.address = profile.address;
      this.city = profile.city;
      this.postcode = profile.postcode;
      this.country = profile.country?.iso_code;
    },
    async syncProfile() {
      const update = {
        company: this.company,
        telephone: this.telephone,
        address: this.address,
        city: this.city,
        postcode: this.postcode,
      };

      await fetch(`${BASE_URL}/drainage/user_settings/`, {
        method: "PATCH",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(update),
      });
      this.updateUserDetails(update);
    },
    async syncCountry(iso_code) {
      let response = await fetch(`${BASE_URL}/drainage/user_settings/country/`, {
        method: "PATCH",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          country: iso_code,
        }),
      });
      if (response.ok) {
        const country = await response.json();
        this.updateUserDetails({country});
        this.country = country.iso_code;
        //Changing the country may change the products available, so update the product list just to be sure
        response = await fetch(`${BASE_URL}/drainage/product/`);
        if (response.ok) this.$store.commit('setDrainageSystems', await response.json());
      }
    },
    translateLanguage(selectedLanguage) {
      return this.$t(`language.${selectedLanguage}`);
    },
    updateLanguage({value: language}) {
      this.updateUserSettings({
        language,
      });
      this.updateUserDetails({
        language,
      });
    },
    updateUserSettings(settings) {
      return fetch(`${BASE_URL}/drainage/user_settings/`, {
        method: "PATCH",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(settings),
      });
    },
  },
}
</script>

<style scoped>
div:nth-child(even) {
  margin: 5px 0 10px;
}

div:last-of-type {
  margin-bottom: 0;
}

input, textarea, .p-dropdown, .p-selectbutton {
  width: 100%;
}

.p-selectbutton {
  display: flex;
}

.p-selectbutton > ::v-deep(*) {
  flex-grow: 1;
}

#address {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
</style>