<template>
  <LogInForm>
    <div class="card form" v-if="userID">
      <h1>{{ $t('passwordReset.title') }}</h1>
      <form action="#">
        <div class="input">
          <label for="password">{{ $t('passwordReset.newPassword') }}</label>
          <InputText id="password" type="password" autocomplete="new-password" v-model="password"
                     :class="{'p-invalid': errors.new_password1}"/>
        </div>
        <small class="input" v-for="error in errors.new_password1" :key="error">
          {{ error }}
        </small>
        <div class="input">
          <label for="passwordConfirmation">{{ $t('passwordReset.confirmPassword') }}</label>
          <InputText type="password" id="passwordConfirmation" autocomplete="new-password"
                     v-model="passwordConfirmation" :class="{'p-invalid': errors.new_password2}"/>
        </div>
        <small class="input" v-for="error in errors.new_password2" :key="error">
          {{ error }}
        </small>
        <div class="input pair">
          <router-link to="/" custom v-slot="{navigate}">
            <Button :label="$t('staffAdmin.back')" icon="pi pi-home" @click="navigate" class="p-button-sm"/>
          </router-link>
          <Button :label="$t('passwordReset.changePassword')" class="p-button-sm" @click="doReset"/>
        </div>
      </form>
    </div>
    <div class="card form" v-else>
      <p>{{ $t('passwordReset.invalidLink') }}</p>
      <p>{{ $t('passwordReset.invalidLinkTip') }}</p>
      <router-link to="/" custom v-slot="{navigate}">
        <Button :label="$t('staffAdmin.back')" icon="pi pi-home" @click="navigate"
                style="float: right;" class="p-button-sm"/>
      </router-link>
    </div>
  </LogInForm>
</template>

<script>
import * as Sentry from '@sentry/vue';
import LogInForm from '@/components/main_content/LogInForm';
import {BASE_URL} from '@/constants';

export default {
  name: 'PasswordResetPage',
  components: {LogInForm},
  props: ['userID'],
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      errors: {},
    };
  },
  methods: {
    async doReset() {
      this.errors = {};
      const response = await fetch(`${BASE_URL}/auth/password/reset/confirm/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          uid: this.userID,
          new_password1: this.password,
          new_password2: this.passwordConfirmation,
        }),
      });
      if (response.ok) {
        return this.$router.push({name: 'home'});
      } else if (response.status === 400) {
        const errors = await response.json();
        if ('token' in errors || 'uid' in errors) {
          return this.$router.push({name: 'bad-password-reset'});
        }
        this.errors = errors;
      } else {
        Sentry.captureMessage("Error resetting user's password", {
          extra: {
            type: 'fetch',
            status: response.status,
            response: await response.text(),
            user: this.userID,
          },
        });
      }
    },
  },
}
</script>

<style scoped>
.input {
  max-width: revert;
}

.pair {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

small.input {
  justify-content: flex-end;
}
</style>