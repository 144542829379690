<template>
  <div ref="root" :style="{position: 'absolute', left: `${x}px`, top: `${y}px`}">
    <header @mousedown.prevent="onClick">
      <slot name="header"></slot>
    </header>
    <main>
      <slot></slot>
    </main>
  </div>
</template>

<script>
export default {
  name: "DraggableDiv",
  props: {
    xStart: {
      type: Number,
      required: true,
    },
    yStart: {
      type: Number,
      required: true,
    },
    /**
     * Whether to recalculate the element offset when the starting position changes.
     * Only needed if one of the parents of this component might change and (relatively) move this around
     */
    noticeMovement: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      xOffset: 0,
      yOffset: 0,
      x: this.xStart,
      y: this.yStart,
    };
  },
  mounted() {
    this.computeOffset();
    this.x += this.xOffset;
    this.y += this.yOffset;
  },
  methods: {
    computeOffset() {
      //We should be at x, y... but we're relative to whatever the next positioned element in the DOM tree is
      //The true offset logic is taken from what jQuery does: https://api.jquery.com/offset/#offset
      /** The absolute left and top positioning of the element, ignoring scroll, relative to the body */
      const {left = 0, top = 0} = this.$refs.root.getBoundingClientRect();
      /** The additional offset from any scrolling the above values will be missing */
      const {pageXOffset: pageLeft = 0, pageYOffset: pageTop = 0} = this.$refs.root.ownerDocument.defaultView;
      //Now we can find out just how far from where we want to be we actually are
      this.xOffset = this.x - left - pageLeft;
      this.yOffset = this.y - top - pageTop;
    },
    onClick(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag, {
        once: true, //We stop needing to watch as soon as we're dropped
        passive: true, //We also don't prevent the default
      });
    },
    onDrag(event) {
      event.preventDefault(); //Avoid dragging over any text/mobile scroll/etc.
      this.x += event.movementX;
      this.y += event.movementY;
    },
    stopDrag() {
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
  },
  unmounted() {
    this.stopDrag();
    //Allow remounting without gently slipping
    this.x -= this.xOffset;
    this.y -= this.yOffset;
  },
  watch: {
    xStart(value) {
      if (this.noticeMovement) this.computeOffset();
      this.x = value + this.xOffset;
    },
    yStart(value) {
      if (this.noticeMovement) this.computeOffset();
      this.y = value + this.yOffset;
    },
  }
}
</script>

<style scoped>
header {
  padding: 0.5rem;
}

main {
  padding: 0 0.5rem;
}
</style>