<template>
  <Accordion :multiple="true" class="accordion" :activeIndex="[runs.length]">
    <AccordionTab :header="run.name" v-for="run in runs" v-bind:key="run.id">
      <table>
        <tr>
          <th>{{ $t('materials.description') }}</th>
          <th>{{ $t('materials.quantity') }}</th>
        </tr>
        <tr v-for="channel in run.results.channel_materials" :key="channel.HAN">
          <td>{{ channel.description }}</td>
          <td>{{ channel.count }}</td>
        </tr>
        <tr v-for="connector in run.results.connectors_materials" :key="connector.HAN">
          <td>{{ connector.description }}</td>
          <td>{{ connector.count }}</td>
        </tr>
        <tr v-for="cap in run.results.endcaps_materials" :key="cap.HAN">
          <td>{{ cap.description }}</td>
          <td>{{ cap.count }}</td>
        </tr>
        <tr v-for="cover in run.results.access_cover_materials" :key="cover.HAN">
          <td>{{ cover.description }}</td>
          <td>{{ cover.count }}</td>
        </tr>
        <tr v-for="box in run.results.box_materials" :key="box.HAN">
          <td>{{ box.description }}</td>
          <td>{{ box.count }}</td>
        </tr>
      </table>
    </AccordionTab>
    <AccordionTab :header="$t('materials.total')" >
      <table>
        <tr>
          <th>{{ $t('materials.description') }}</th>
          <th>{{ $t('materials.quantity') }}</th>
        </tr>
        <tr v-for="channel in total.channel_materials" :key="channel.HAN">
          <td>{{ channel.description }}</td>
          <td>{{ channel.count }}</td>
        </tr>
        <tr v-for="connector in total.connectors_materials" :key="connector.HAN">
          <td>{{ connector.description }}</td>
          <td>{{ connector.count }}</td>
        </tr>
        <tr v-for="cap in total.endcaps_materials" :key="cap.HAN">
          <td>{{ cap.description }}</td>
          <td>{{ cap.count }}</td>
        </tr>
        <tr v-for="cover in total.access_cover_materials" :key="cover.HAN">
          <td>{{ cover.description }}</td>
          <td>{{ cover.count }}</td>
        </tr>
        <tr v-for="box in total.box_materials" :key="box.HAN">
          <td>{{ box.description }}</td>
          <td>{{ box.count }}</td>
        </tr>
      </table>
    </AccordionTab>
  </Accordion>
</template>

<script>
export default {
  name: "MaterialList",
  computed: {
    runs() {
      return this.$store.getters.runs.filter(run => run.results.calculated);
    },
    total() {
      //Short cut merging the total together if there's nothing to merge
      if (this.runs.length < 2) return this.runs[0].results;

      const mergeMaterials = (materialFinder) => [...this.runs.reduce((total, run) => {
          for (const channel of materialFinder(run.results)) {
            if (channel.HAN === 'X') {
              total.set(Symbol('manual-channel'), channel); //Avoid merging concrete/steel channels together
            } else if (!total.has(channel.HAN)) {
              total.set(channel.HAN, {...channel}); //Clone the object to avoid the count increase propagating backwards
            } else {
              total.get(channel.HAN).count += channel.count;
            }
          }

          return total;
      }, new Map()).values()].sort((a, b) => a.sort - b.sort);

      return {
        channel_materials: mergeMaterials(results => Object.values(results.channel_materials)),
        connectors_materials: mergeMaterials(results => results.connectors_materials),
        endcaps_materials: mergeMaterials(results => results.endcaps_materials),
        access_cover_materials: mergeMaterials(results => results.access_cover_materials),
        box_materials: mergeMaterials(results => results.box_materials),
      };
    },
  },
  methods: {
  }
}
</script>

<style scoped>
.accordion {
  max-width: 1250px;
  margin: auto
}

.accordion ::v-deep(.p-accordion-content) {
  padding: 5px;
}

table {
  width: 100%;
}

th, td {
  padding: 3px;
}

th:nth-child(2) {
  min-width: max-content;
  white-space: nowrap;
}

th:nth-child(1) {
  width: 100%;
  text-align: left;
}

td:nth-child(2) {
  text-align:center;
}

div {
  font-size: 0.8rem;
}

</style>
