<template>
    <div ref="container">
        <canvas ref="canvas" height="101"></canvas>
        <Button :label="$t(`edit${editing === 'head' ? 'ing' : ''}Head`)" :disabled="editing === 'head'"
                @click="$emit('update:editing', 'head')"/>
        <Button :label="$t(`edit${editing === 'discharge' ? 'ing' : ''}Discharge`)" style="float: right"
                @click="$emit('update:editing', 'discharge')" :disabled="editing === 'discharge'"/>
    </div>
</template>

<script>
import {fabric} from 'fabric';
import {mapGetters} from 'vuex';

const channelHeight = 100;
const trashboxExtraHeight = 25;

export default {
    name: 'ChannelPreview',
    props: ['editing'],
    emits: ['update:editing'],
    data() {
        return {
            /** @type {fabric.StaticCanvas} */
            canvas: null,
        };
    },
    computed: {
        ...mapGetters({
            allChannels: 'getChannelChoices',
            channelLength: 'getChannelLength',
            rawChannels: 'getRunChannels',
            runLength: 'getChannelsConfirmedLength',
        }),
        ...mapGetters([
            'hasHeadCutting',
            'getHeadCuttingDistance',
            'hasDischargeCutting',
            'getDischargeCuttingDistance',
        ]),
        runChannels() {
            const channelMap = new Map(this.rawChannels.map(channel => [channel, this.allChannels.indexOf(channel)]));
            return this.rawChannels.slice().sort((a, b) => channelMap.get(a) - channelMap.get(b));
        },
        trashboxLength() {
            return this.$store.getters.selectedRun.dischargeTrashboxLength;
        },
        rawRunLength() {//Ignoring any channel cutting
            return this.rawChannels.reduce((totalLength, channel) => {
                console.assert(Number.isFinite(channel.number));
                console.assert(Number.isFinite(channel.channel_length_mm));
                return totalLength + channel.number * channel.channel_length_mm / 1000;
            }, this.trashboxLength);
        },
    },
    mounted() {
        const canvas = this.$refs.canvas;
        canvas.width = this.$refs.container.offsetWidth;
        this.canvas = new fabric.StaticCanvas(canvas, {
            renderOnAddRemove: false,
        });
        this.drawCanvas();
        window.addEventListener('resize', this.resizeCanvas, {passive: true});
    },
    unmounted() {
        window.removeEventListener('resize', this.resizeCanvas);
    },
    methods: {
        drawCanvas() {
            if (!this.canvas.isEmpty()) this.canvas.clear();

            const length_mm = Math.max(this.channelLength + this.trashboxLength, this.rawRunLength) * 1000;
            const xScale = (this.canvas.getWidth() - 1) / length_mm;
            let deepest_mm = Math.max(...this.runChannels.map(channel => channel.max_downstream_water_depth_mm));
            if (this.trashboxLength > 0) deepest_mm += trashboxExtraHeight;
            const yScale = channelHeight / deepest_mm;

            let left = 0;
            for (const channel of this.runChannels) {
                //Round down so everything always fits, what's 0.99 pixels short anyway
                const width = Math.floor(channel.number * channel.channel_length_mm * xScale);
                const height = Math.floor(channel.max_downstream_water_depth_mm * yScale);
                this.canvas.add(new fabric.Rect({
                    left,
                    top: 0,
                    fill: 'transparent',
                    stroke: 'black',
                    width,
                    height,
                }));
                left += width;
            }
            if (this.trashboxLength > 0) {
                const width = this.trashboxLength * 1000 * xScale;
                const height = deepest_mm * yScale;
                this.canvas.add(new fabric.Rect({
                    left,
                    top: 0,
                    fill: 'transparent',
                    stroke: 'black',
                    width,
                    height,
                }))
            }
            if (this.rawRunLength !== this.runLength && this.runChannels.length) {
                const cuts = [];
                if (this.hasHeadCutting) {
                    cuts.push({
                        x: this.getHeadCuttingDistance * xScale,
                        y: this.runChannels[0].max_downstream_water_depth_mm * yScale,
                    });
                }
                if (this.hasDischargeCutting) {
                    cuts.push({
                        x: left - this.getDischargeCuttingDistance * xScale,
                        y: this.runChannels[this.runChannels.length - 1].max_downstream_water_depth_mm * yScale,
                    });
                }
                this.canvas.add(...cuts.map(cut => new fabric.Line([cut.x, 0, cut.x, cut.y], {//x1, y1, x2, y2
                    strokeDashArray: [5, 5],
                    stroke: '#B7182E',
                })));
            }

            this.canvas.renderAll();
        },
        resizeCanvas() {
            //Remove any influence on the available space based on how large the canvas used to be, then fill the space
            this.canvas.setWidth(0, {cssOnly: true});
            this.canvas.clear();
            this.canvas.setWidth(this.$refs.container.offsetWidth);
            this.drawCanvas();
        },
    },
    watch: {
        runChannels: {
            deep: true,
            handler: 'drawCanvas',
        },
        hasHeadCutting: 'drawCanvas',
        getHeadCuttingDistance: 'drawCanvas',
        hasDischargeCutting: 'drawCanvas',
        getDischargeCuttingDistance: 'drawCanvas',
    },
};
</script>

<style scoped>
div {
    width: 100%;
}

button {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
}
</style>
