<template>
  <ConfirmPopup class="project-load-popup"/>
  <data-table :value=projects :loading="projects === null" :rowHover="true" @row-click="loadProject"
              sortMode="single" sortField="created_at" :sortOrder="-1" class="p-datatable-sm"
              :paginator="true" :rows="10" :rowsPerPageOptions="[10, 25, 50]"
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown PrevPageLink PageLinks NextPageLink"
              :currentPageReportTemplate="$t('load.pagination')"
              :row-class="row => ({empty: !row, invalid: row && row.is_empty})">
    <Column field="name" :header="$t('load.name')" :sortable="true"></Column>
    <Column field="location" :header="$t('load.location')" :sortable="true"></Column>
    <Column field="reference" :header="$t('load.reference')" :sortable="true"></Column>
    <Column field="created_at" :header="$t('load.date')" :sortable="true" style="width: 50px;">
      <template #body="{data}">
        <span v-if="data">{{ formatDate(data.created_at) }}</span>
      </template>
    </Column>
    <Column field="saved_by" :header="$t('load.creator')" :sortable="true"></Column>
    <Column class="button-column">
      <template #body="{data}">
        <Button v-if="data" icon="pi pi-share-alt" class="project-button"
                @click="shareProject($event, data)" v-tooltip.left="$t('load.share')"/>
        <div v-else class="project-button"><!-- Set row height --></div>
      </template>
    </Column>
    <Column class="button-column">
      <template #body="{data}">
        <Button v-if="data" icon="pi pi-trash" class="project-button"
                @click="deleteProject(data)" v-tooltip="$t('load.delete')"/>
        <div v-else class="project-button"><!-- Set row height --></div>
      </template>
    </Column>
    <template #empty>
      <div style="text-align: center;">{{ $t('load.noProjects') }}</div>
    </template>
  </data-table>
</template>

<script>
import * as Sentry from '@sentry/vue';
import ConfirmPopup from 'primevue/confirmpopup';
import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";

export default {
  name: "LoadProject",
  components: {ConfirmPopup},
  emits: ['close', 'load'],
  data() {
    return {
      projects: null,
    };
  },
  async mounted() {
    const response = await fetch(`${BASE_URL}/drainage/projects/`);
    const data = await response.json();
    for (const row of data) {
      row.created_at = new Date(row.created_at); //Save making temporary objects
    }
    if (data.length) data.length = Math.max(data.length, 10); //Ensure there's space for at least 10 projects
    this.projects = data;
  },
  methods: {
    /** @param {Date} value The date to format */
    formatDate(value) {
      return value.toLocaleDateString(/* local formatting */undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    async loadProject({data: project}) {
      if (!project) return; //Placeholder row
      if (project.is_empty) return alert('No runs in project!');
      this.$emit('load', {id: project.id});
    },
    async shareProject({currentTarget}, project) {
      const response = await fetch(`${BASE_URL}/drainage/share/`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({id: project.id}),
      });

      if (response.ok) {
        const link = (await response.text()).slice(1, -1);
        this.$confirm.require({
          target: currentTarget,
          message: this.$t('load.shareText', {link}),
          icon: 'pi pi-share-alt',
          acceptLabel: this.$t('load.copyLink'),
          accept: () => navigator.clipboard.writeText(link),
          rejectClass: 'project-load-hidden', //Don't need the other button
        });
      } else {
        console.warn("Project sharing went badly", response);
        Sentry.captureMessage('Error sharing project', {
          extra: {
            type: 'fetch',
            status: response.status,
            response: await response.text(),
            project: project.id,
          },
        });
      }
    },
    async deleteProject(project) {
      const response = await fetch(`${BASE_URL}/drainage/delete/`, {
          method: "DELETE",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({id: project.id}),
      });

      if (response.ok) {
        this.projects.splice(this.projects.indexOf(project), 1);
      } else {
        console.warn("Project deleting went badly", response);
        Sentry.captureMessage('Error deleting project', {
          extra: {
            status: response.status,
            response: await response.text(),
            project: project.id,
          },
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.project-button {
  width: 30px !important;
  height: 30px;
}

.p-datatable {
  min-width: 50vw; //Ensure the table is at least 50% of the screen wide

  ::v-deep(tr) {
    font-size: 0.8rem;

    &.empty {
      //Hide the hover effects for empty rows
      background: white !important;
      cursor: unset !important;
    }

    @each $row, $modifier in (even: -1, odd: 1) {
      &.invalid:nth-child(#{$row}) {//var(--red), but 12.5% and 25% opacity
        background: repeating-linear-gradient($modifier * 45deg,
                rgba(183, 24, 46, 0.125) 0 0.5rem,
                rgba(183, 24, 46, 0.25) 0.5rem 1rem
        ) !important;
        cursor: unset !important;
      }
    }
  }

  &.p-datatable-hoverable-rows ::v-deep(.p-datatable-tbody) > tr:not(.p-highlight):hover {
    //Change the cursor when hovering only over the project rows (rather than the column headers too)
    cursor: pointer;
  }

  ::v-deep(td).button-column {
    @extend .project-button;
    padding: 3px !important; //Reduce the large padding the table cells have
  }

  ::v-deep(.p-paginator) {
    //The pagination controls all have their own padding/margins already
    padding-left: 0;
    padding-right: 0;

    .p-paginator-current {
      //PrimeVue has a pointer cursor for the pagination text for some reason
      cursor: default;
    }

    .p-paginator-rpp-options {
      //Push the rows per page to the left (and other pagination controls to the right)
      margin-right: auto;
    }
  }
}
</style>

<style>
.project-load-popup.p-confirm-popup {
  margin-top: 4px;
  max-width: 25rem;
}

.project-load-popup.p-confirm-popup-flipped {
  margin-top: -4px;
}

.project-load-popup span {
  white-space: pre-line;
}

.project-load-hidden {
  display: none !important;
}
</style>