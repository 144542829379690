<template>
  <TrueInputNumber ref="input" v-bind="$attrs"
                   @update:model-value="$emit('update:modelValue', $event)" @keydown="onInput($event)"/>
</template>

<script>
import TrueInputNumber from 'primevue/inputnumber';

export default {
  name: "InputNumber",
  inheritAttrs: false, //Optional here as v-bind="$attrs" is on the root element anyway (which is default behaviour)
  emits: ['update:modelValue'], //Declaring this needs the @update:model-value to pass updates up
  components: {
    TrueInputNumber,
  },
  computed: {
    allowsDecimals() {
      return this.$attrs.maxFractionDigits > 0 || this.$attrs.minFractionDigits > 0 ||
          this.$attrs['max-fraction-digits'] > 0 || this.$attrs['min-fraction-digits'] > 0;
    },
    prefixLength() {
      return this.$attrs.prefix?.length ?? 0;
    },
    suffixLength() {
      return this.$attrs.suffix?.length ?? 0;
    },
    separator() {
      //Work out the separator based on what 1.1 gets formatted as (either still 1.1 or 1,1)
      return this.$refs.input.getFormatter.formatToParts(1.1).find(part => part.type === 'decimal').value;
    },
  },
  methods: {
    onInput(event) {
      //console.debug(event, this.$attrs.modelValue, this.allowsDecimals, this.$attrs);

      if (this.allowsDecimals && event.key === this.separator) {
        const {selectionStart: start, selectionEnd: end, value} = event.target;

        //console.debug(event.target.value, start, end, event.target.value.substring(start, end));
        if (start === end) {//Nothing selected, just a cursor
          if (value.length !== 0) return; //Only if we're filling the box from empty
        } else {//Range selected, see what we're squishing
          const suffixIndex = value.length - this.suffixLength;

          //Only if the selection includes the start and won't leave a second trailing separator
          if (start !== this.prefixLength || value.substring(end, suffixIndex).includes(this.separator)) return;
        }
        //console.debug(start, end, event.target.value.substring(start, end));

        event.preventDefault(); //Don't pass this to any other handlers
        this.$emit('update:modelValue', 0.0);
        this.$nextTick(() => {
          //Leave this until next tick to avoid any ordering funkiness with the underlying InputNumber changing this too
          event.target.setSelectionRange(this.prefixLength + 2, this.prefixLength + 3);
        });
      }
    }
  }
}
</script>

<style scoped>
</style>