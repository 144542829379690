<template>
  <div id="canvas-wrapper">
    <canvas ref="canvas" :width="canvasWidth_px" :height="canvasHeight_px" id="canvas"></canvas>
  </div>
</template>

<script>
import {fabric} from "fabric";

export default {
  name: "UniformCatchment",
  props: ['currentPermeability', 'channelLength_m', 'catchmentArea_sqm'],
  data() {
    return {
      canvasHeight_px: 350,
      padding: 20,
      strokeWidth: 1,
      channelWidth_px: 10,
      windowWidth: window.innerWidth
    }
  },
  mounted() {
    const ref = this.$refs.canvas;
    this.canvas = new fabric.Canvas(ref, {
      selectable: false,
      selection: false,
    });
    this.populateDrawing();
  },
  unmounted() {
    if (this.channelInterval) clearInterval(this.channelInterval);
  },
  computed: {
    canvasWidth_px() {
      if (this.windowWidth < 1900) {
        // Laptop screen
        return 500
      } else {
        // Desktop screen
        return 1000
      }
    },
    scale() {
      const width_m = this.catchmentArea_sqm / this.channelLength_m;
      // console.log(Math.min(this.canvasHeight_px / width_m, this.canvasWidth_px / this.channelLength_m))
      return Math.min(this.canvasHeight_px / width_m, this.canvasWidth_px / this.channelLength_m);
    },
    width_m() {
      return this.catchmentArea_sqm / this.channelLength_m
    },
    ratio() {
      return this.channelLength_m / this.width_m
    },
    channelLength_px() {
      return this.channelLength_m * this.scale

    },
    sideAreaWidth_px() {
      return (((this.catchmentArea_sqm / this.channelLength_m) / 2) * this.scale)
    },
    maxWidth_px() {
      return (this.middleBottomCornerRight.y - this.channelWidth_px) - this.topLeftCorner.y
    },
    channelLength() {
      const channelLength = this.$store.getters.getChannelLength
      return channelLength
    },
    topLeftCorner() {
      return {x: 0, y: 0}
    },
    topRightCorner() {
      return {x: this.canvasWidth_px - this.strokeWidth, y: 0}
    },
    bottomRightCorner() {
      return {x: this.canvasWidth_px - this.strokeWidth, y: this.canvasHeight_px}
    },
    bottomLeftCorner() {
      return {x: 0, y: this.canvasHeight_px}
    },
    middleTopCornerLeft() {
      return {
        x: 0,
        y: (this.canvasHeight_px / 2) - this.channelWidth_px / 2 + this.strokeWidth
      }
    },
    middleTopCornerRight() {
      return {
        x: this.canvasWidth_px - this.strokeWidth,
        y: (this.canvasHeight_px / 2) - this.channelWidth_px / 2 + this.strokeWidth
      }
    },
    middleBottomCornerLeft() {
      return {x: 0, y: (this.canvasHeight_px / 2) + this.channelWidth_px / 2 + this.strokeWidth}
    },
    middleBottomCornerRight() {
      return {
        x: this.canvasWidth_px - this.strokeWidth,
        y: (this.canvasHeight_px / 2) + this.channelWidth_px / 2 + this.strokeWidth
      }
    }
  },
  methods: {
    removeAllObjects() {
      const allObjects = this.canvas.getObjects()
      allObjects.map(o => this.canvas.remove(o))
    },
    populateDrawing() {
      this.removeAllObjects()
      this.drawChannel()
      this.drawAreas()
    },
    drawAreas() {
      const leftAreaPoints = [
        {x: this.topLeftCorner.x, y: this.middleTopCornerRight.y - this.sideAreaWidth_px},
        {x: this.topLeftCorner.x + this.channelLength_px, y: this.middleTopCornerRight.y - this.sideAreaWidth_px},
        {x: this.topLeftCorner.x + this.channelLength_px, y: this.middleTopCornerRight.y},
        {x: this.middleTopCornerLeft.x, y: this.middleTopCornerLeft.y},
      ]
      const leftArea = new fabric.Polygon(leftAreaPoints, {
        id: 'area',
        left: 0,
        top: this.middleTopCornerRight.y - this.sideAreaWidth_px,
        fill: this.currentPermeability.colour,
        strokeWidth: this.strokeWidth,
        selectable: false,
        hoverCursor: 'normal',
        hasBorders: false,
      })
      this.canvas.add(leftArea);

      const rightAreaPoints = [
        {x: this.middleBottomCornerLeft.x, y: this.middleBottomCornerLeft.y},
        {x: this.middleBottomCornerLeft.x + this.channelLength_px, y: this.middleBottomCornerRight.y},
        {
          x: this.middleBottomCornerLeft.x + this.channelLength_px,
          y: this.middleBottomCornerLeft.y + this.sideAreaWidth_px
        },
        {x: this.middleBottomCornerLeft.x, y: this.middleBottomCornerLeft.y + this.sideAreaWidth_px},
      ]
      const rightArea = new fabric.Polygon(rightAreaPoints, {
        left: 0,
        top: this.middleBottomCornerRight.y,
        fill: this.currentPermeability.colour,
        strokeWidth: this.strokeWidth,
        selectable: false,
        hoverCursor: 'normal',
        hasBorders: false,
      })
      this.canvas.add(rightArea);
    },
    drawChannel() {
      const channelPoints = [
        {x: this.middleTopCornerLeft.x, y: this.middleTopCornerLeft.y},
        {x: this.middleTopCornerLeft.x + this.channelLength_px, y: this.middleTopCornerRight.y},
        {x: this.middleTopCornerLeft.x + this.channelLength_px, y: this.middleTopCornerRight.y + this.channelWidth_px},
        {x: this.middleTopCornerLeft.x, y: this.middleTopCornerRight.y + this.channelWidth_px},
      ]
      const channel = new fabric.Polygon(channelPoints, {
        left: 0,
        top: this.middleTopCornerRight.y,
        fill: 'lightblue',
        strokeWidth: this.strokeWidth,
        selectable: false,
        hoverCursor: 'normal',
        hasBorders: false,
      })
      this.canvas.add(channel);

      const flowIndicator = new fabric.Triangle({
        left: channel.left + this.channelWidth_px,
        top: channel.top,
        width: this.channelWidth_px,
        height: this.channelWidth_px,
        fill: 'mediumblue',
        angle: 90,
        selectable: false,
        hoverCursor: 'normal',
      });
      this.canvas.add(flowIndicator);

      if (this.channelInterval) clearInterval(this.channelInterval);
      //This slightly strange looking logic loops the flow animation without recursion
      const animate = () => {
        flowIndicator.animate('left', this.channelLength_px, {
          onChange: this.canvas.renderAll.bind(this.canvas),
          onComplete: () => {
            flowIndicator.left = 0;
            this.canvas.renderAll(); //Not strictly necessary, but hides the triangle until the next cycle
          },
          duration: 10000, //Slightly less than the interval timeout to ensure it's always finished
          easing: fabric.util.ease.easeInCubic(),
        });
      };
      animate(); //Avoid waiting the interval timeout for the first animation
      this.channelInterval = setInterval(animate, 10500);
    },
  },
  watch: {
    currentPermeability() {
      this.populateDrawing()
    },
    channelLength_m() {
      this.populateDrawing()
    },
    catchmentArea_sqm() {
      this.populateDrawing()
    }
  }
}
</script>

<style scoped>

#canvas {
  /*border: 1px solid red;*/
}
#canvas-wrapper {
  display:flex;
  justify-content: center;
}


</style>
