<template>
  <Dropdown :modelValue="selectedPermeability" :options="permeabilities" data-key="name"
            :optionLabel="({translationKey}) => $t(`permeability.${translationKey}`)"
            :disabled="disabled"
            :placeholder="$t('selectAFill')"
            :class="[selectedPermeability.cssName]"
            @change="handlePermeabilityChange($event)" @focus="$emit('focus', $event)"/>
  <InputNumber
      id="permeability-value" inputStyle="width: 30px;"
      :modelValue="permeabilityValue" :disabled="disabled"
      mode="decimal" :min="0" :max="1" :step="0.1"
      :locale="$i18n.locale"
      :minFractionDigits="1" :maxFractionDigits="1"
      @update:modelValue="$emit('update:permeabilityValue', $event)"
      @input="handleCustomPermeability($event)" @focus="$event.target.select(); $emit('focus', $event)"/>
</template>

<script>
import permeabilities, {
  ConcretePavement,
  Custom,
} from "@/components/main_content/tabs_content/design_run/PermeabilityUtils";

export default {
  name: "PermeabilitySelector",
  props: {
    selectedPermeability: {
      type: Object,
      default: ConcretePavement,
    },
    permeabilityValue: {
      type: Number,
      default: ConcretePavement.value,
    },
    disabled: Boolean,
  },
  emits: ['update:selectedPermeability', 'update:permeabilityValue', 'permeabilityChange', 'focus'],
  data() {
    return {
      permeabilities,
    };
  },
  methods: {
    handlePermeabilityChange({value}) {
      this.$emit('update:selectedPermeability', value);
      this.$emit('update:permeabilityValue', value.value);
      this.$emit('permeabilityChange', value, value.value);
    },
    handleCustomPermeability({value}) {
      this.$emit('update:selectedPermeability', Custom);
      this.$emit('update:permeabilityValue', value);
      this.$emit('permeabilityChange', Custom, value);
    },
  },
}
</script>

<style scoped lang="scss">
@use "src/assets/scss/materials";

.p-dropdown {
  width: 200px;
  @include materials.permeability-dropdown
}

span {
  margin-left: 0.5rem;
}
</style>