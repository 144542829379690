<template>
  <Dialog :header="$t('superCriticalWarning.title')" style="max-width: 800px;" :closable="false"
          :visible="display" @update:visible="$emit('update:display', $event)">
    <p>{{ $t('superCriticalWarning.textA') }}</p>
    <p>{{ $t('superCriticalWarning.textB') }}</p>
    <p>{{ $t('superCriticalWarning.textC') }}</p>
    <p>{{ $t('superCriticalWarning.textD') }}</p>
    <template #footer>
      <footer>
        <Checkbox id="super-critical-warning-skip" v-model="skip" binary/>
        <label :for="'super-critical-warning-skip'">{{ $t('superCriticalWarning.skip') }}</label>
        <Button :label="$t('ok')" icon="pi pi-check" autofocus @click="$emit('update:display', false)"/>
      </footer>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'SuperCriticalWarning',
  props: ['display'],
  emits: ['update:display'],
  computed: {
    skip: {
      get() {
        return this.$store.state.skipSuperCriticalFlowWarnings;
      },
      set(value) {
        this.$store.commit('setSuperCriticalWarnings', value);
      }
    },
  },
}
</script>

<style scoped>
p:first-of-type {
  margin-top: 0;
}

p:last-of-type {
  margin-bottom: 0;
}

footer {
  display: flex;
  align-items: center;
}

label {
  margin-left: 0.5rem;
  margin-right: auto;
}
</style>