<template>
    <div class="cutting-section">
        <div class="cutting-settings">
            <Checkbox v-model="doCutting" binary :disabled="!channel || isLChannel" :id="`enable-cutting-${id}`"/>
            <label :for="`enable-cutting-${id}`" class="header-label">{{
                    $t(`cuttingAt${mirrorCut ? 'Fir' : 'La'}stElement`)
                }}</label>
            <span style="grid-area: empty"></span>

            <label :for="`cutting-distance-${id}`"
                   :style="{color: doCutting ? 'black' : 'lightgray'}">{{ $t('lengthFromBeginning') }}</label>
            <InputNumber :disabled="!doCutting"
                         v-model="lengthFromEnd"
                         mode="decimal"
                         :min="0" :max="channel?.channel_length_mm"
                         suffix=" mm" :locale="$i18n.locale"
                         @focus="$event.target.select()"
                         :id="`cutting-distance-${id}`"/>

            <label :for="`cutting-angle-${id}`"
                   :style="{color: doCutting ? 'black' : 'lightgray'}">{{ $t('angleOfCutting') }} (&thetasym;)</label>
            <InputNumber :disabled="!doCutting"
                         v-model="cuttingAngle"
                         mode="decimal"
                         :min="1" :max="90" :maxFractionDigits="1"
                         suffix="°" :locale="$i18n.locale"
                         @focus="$event.target.select()"
                         :id="`cutting-angle-${id}`"/>

            <label :for="`switch-cutting-side-${id}`"
                   :style="{color: doCutting ? 'black' : 'lightgray'}">{{ $t('switchSide') }}</label>
            <InputSwitch v-model="switchCuttingSide" :id="`switch-cutting-side-${id}`" :disabled="!doCutting"/>
        </div>

        <CuttingAtLast v-if="channel && !isConcrete" :enabled="doCutting" :channel="channel" :mirror="mirrorCut"
                       :distance_mm="distance" :angle-of-cutting="angle" :switchValue="switchCuttingSide"
                       class="cutting-at-last"/>
    </div>
</template>

<script>
import CuttingAtLast from './CuttingAtLast.vue';

import {v4 as uuidv4} from 'uuid';

export default {
    name: 'ChannelCutting',
    components: {CuttingAtLast},
    props: ['enabled', 'channel', 'distance', 'angle', 'leftSide', 'mirrorCut'],
    emits: ['update:enabled', 'update:distance', 'update:angle', 'update:leftSide'],
    data() {
        return {
            id: null,
        };
    },
    beforeMount() {
        this.id = uuidv4();
    },
    computed: {
        doCutting: {
            get() {
                return this.enabled;
            },
            set(enabled) {
                this.$emit('update:enabled', enabled);
            }
        },
        lengthFromEnd: {
            get() {
                return this.distance;
            },
            set(distance) {
                this.$emit('update:distance', distance);
            }
        },
        cuttingAngle: {
            get() {
                return this.angle;
            },
            set(angle) {
                this.$emit('update:angle', angle);
            }
        },
        switchCuttingSide: {
            get() {
                return this.leftSide;
            },
            set(value) {
                this.$emit('update:leftSide', value);
            }
        },
        isLChannel() {
            return this.channel?.name.endsWith('L');
        },
        isConcrete() {
            return this.$store.getters.getDrainageSystem?.product_type === 'M';
        },
    },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 0.7rem;
}

.cutting-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; //Avoid stretching the cutting-settings grid
    gap: 1rem;
}

.cutting-settings {
    flex: 0 1 400px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: min-content auto max-content;
    grid-template-areas: ". title title"
                         "empty . ."
                         "empty . ."
                         "empty . .";
    align-items: center;
    gap: 0.5rem;

    .p-inputnumber {
        justify-self: center;
    }

    ::v-deep(.p-inputtext) {
        font-size: 0.7rem;
        width: 60px;
        padding: 0.125rem;
        text-align: right;
    }
}

.p-inputswitch {
    height: 1rem;
    justify-self: center;

    ::v-deep(.p-inputswitch-slider::before) {
        margin-top: -0.375rem;
        height: 0.7rem;
        width: 0.7rem;
    }

    &.p-inputswitch-checked ::v-deep(.p-inputswitch-slider::before) {
        transform: translateX(1.75rem);
    }
}

.header-label {
    grid-area: title;
    font-weight: bold;
}
</style>
