<template>
  <Dropdown :model-value="country" @update:model-value="$emit('update:country', $event)"
            :options="countries" option-label="name" option-value="iso_code" panel-class="country-dropdown">
    <template #option="{ option: country }">
      <span :class="['fi', `fi-${country.iso_code.toLowerCase()}`]"></span>
      <span>{{ country.name }}</span>
    </template>
  </Dropdown>
</template>

<script>
import 'flag-icons/css/flag-icons.css';
import {refresh} from "@/auth";
import {BASE_URL} from "@/constants";

export default {
  name: "CountryDropdown",
  props: ['country', 'countryList'],
  emits: ['update:country'],
  data() {
    return {
      countries: this.countryList || [],
    };
  },
  async mounted() {
    if (this.countryList) return; //Already have the list, don't need to do this
    await refresh(); //The result is unimportant, we're just reiterating if allowed to see special countries
    const response = await fetch(`${BASE_URL}/drainage/countries/`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });

    if (response.ok) {
      this.countries = await response.json();
    } else {
      console.error("Failed to get country list", response);
    }
  },
}
</script>

<style lang="scss">
.country-dropdown .p-dropdown-items {
  .fi::before {
    visibility: hidden; //Avoid stretching the line height
  }

  & > .p-dropdown-item {
    padding: 0.5rem 0 !important;

    & > span:first-of-type {
      margin: 0 0.5rem;
    }

    & > span:last-of-type {
      padding-right: 1rem;
    }
  }
}

.fi-ww {
  background-image: url("~@/assets/images/globe.svg");
}

.fi-rd {
  background-image: url("~@/assets/images/conical_flask.svg");
}
</style>