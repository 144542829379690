<template>
  <div id="rainfall-data-form">
    <h3>Rainfall Data</h3>
    <p>Move map around with cursor (hand).</p>
    <p>Click on map location for specific rainfall data.</p>
    <p>Refer to HELP file for further information regarding rainfall data.</p>
    <div class="row">
      <h4>Location:</h4>
      <h4 id="location">{{ town }}</h4>
    </div>
    <div class="row">
      <label for="returning-period">Return Period (years)</label>
      <input type="text" v-model.number="returnPeriod" id="returning-period" @focus="$event.target.select()" @input="onSettingChange"/>
    </div>
    <div class="row">
      <label :for="'climate-change'">Climate Change</label>
      <select v-model="selectedClimateChange" id="climate-change" @change="onSettingChange">
        <option :value="option.value" v-for="option in climateChangeOptions" v-bind:key="option.value">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="row">
      <label for="storm-duration">Storm Duration (mins)</label>
      <input type="text" v-model.number="stormDuration" id="storm-duration" @focus="$event.target.select()"  @input="onSettingChange"/>
    </div>
    <div class="row">
      <label for="rainfall-int">Rainfall Intensity:</label>
      <p id="rainfall-int" class="ri">{{ units.convert(rainfallIntensity).toPrecision(3) }} {{ units.name }}</p>
    </div>
    <div class="row">
      <p class="M5-60" id="M5-60">M5-60: {{ M5 }}</p>
      <p class="r" id="r">r: {{ r }}</p>
    </div>
    <hr>
    <div class="row">
      <div>
        <p class="header">DURATION</p>
        <p class="ri-p" v-for="duration in rainfallIntensities" :key="duration[0]">
          {{ duration[0] }}
        </p>
      </div>
      <div>
        <p class="header">INTENSITY</p>
        <p class="ri-p" v-for="duration in rainfallIntensities" :key="duration[0]">
          {{ duration[1] }}
        </p>
      </div>
    </div>
    <hr>
    <div class="actions">
      <Button :disabled="!town" @click="handleOKClick" label="OK"/>
      <Button @click="$emit('closeUKRainfallMap')" label="CANCEL"/>
    </div>
  </div>
  <div ref="mapContainer" id="mapContainer">
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
//Leaflet has some issues finding its default pin icons by with Webpack
//So rather than fix it, they suggest this as a workaround
//https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-483402699
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";
import {mapGetters} from "vuex";

export default {
  name: "UKRainfallMap",
  props: ['displayUKRainfallMap'],
  emits: ['closeUKRainfallMap'],
  data() {
    const rainfall = this.$store.getters.getRainfallData;

    return {
      climateChangeOptions: [
        {name: '0', value: 0},
        {name: '+5%', value: 5},
        {name: '+10%', value: 10},
        {name: '+15%', value: 15},
        {name: '+20%', value: 20},
        {name: '+30%', value: 30},
        {name: '+40%', value: 40},
      ],

      town: rainfall.town,
      location_id: rainfall.location_id,
      locationIndex: rainfall.location_index,
      rainfallIntensity: rainfall.rainfall_mm_per_hr,
      M5: rainfall.M5 ?? '-',
      r: rainfall.r ?? '-',
      rainfallIntensities: [
        '5 min',
        '10 min',
        '15 min',
        '30 min',
        '1 hour',
        '2 hours',
        '4 hours',
        '8 hours',
      ].map((time, i) => [time, rainfall.intensities[i] ?? '-']),

      selectedClimateChange: rainfall.climate_change_factor,
      returnPeriod: rainfall.return_period,
      stormDuration: rainfall.storm_duration,
    }
  },
  computed: {
    ...mapGetters({
      units: 'projectRainfallUnits',
    }),
  },
  mounted() {
    this.setupLeafletMap();
  },
  methods: {
    handleOKClick() {
      this.$store.commit('setRainfallData', {
        override: false, //We're using map data
        town: this.town,
        location_id: this.location_id,
        location_index: this.locationIndex,
        rainfall_mm_per_hr: this.rainfallIntensity,
        intensities: this.rainfallIntensities.map(time => time[1]),
        M5: this.M5,
        r: this.r,
        climate_change_factor: this.selectedClimateChange,
        return_period: this.returnPeriod,
        storm_duration: this.stormDuration,
        fiveMinuteRate: parseFloat(this.rainfallIntensities[0][1]),
        fifteenMinuteRate: parseFloat(this.rainfallIntensities[2][1]),
        sixtyMinuteRate: parseFloat(this.rainfallIntensities[4][1]),
      });

      const defaultRain = this.$store.getters.getRainfallDefaults;
      //If the town or storm duration have changed, or the rainfall intensities otherwise changed
      const changedDefault = defaultRain.town !== this.town || defaultRain.storm_duration !== this.stormDuration ||
          defaultRain.intensities.some((rain, i) => rain !== this.rainfallIntensities[i][1]);

      this.$emit('closeUKRainfallMap', changedDefault);
    },
    updateRainfall(data) {
      //Only clicks on the map give a town and location ID
      if (data.town) this.town = data.town;
      if (data.rainfallLocationId) this.location_id = data.rainfallLocationId;
      this.locationIndex = data.LocationIndex;
      this.rainfallIntensity = data.rainfallIntensity;
      this.M5 = data.M5;
      this.r = data.r;
      for (let i = 0; i < this.rainfallIntensities.length; i++) {
        this.rainfallIntensities[i][1] = data.intensity[i][1].toFixed();
      }
    },
    setupLeafletMap() {
      const southWest = L.latLng(49.5, -7.2),
          northEast = L.latLng(60, 1.9),
          theseBounds = L.latLngBounds(southWest, northEast);


      const mapDiv = L.map(this.$refs.mapContainer, {
            center: [53.505, -1.5],
            minZoom: 7,
            zoom: 7,
            maxZoom: 12,
            maxBounds: theseBounds
          }
      )
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapDiv);
      //Move the attribution text to the opposite side to the default, reduces the minimum vertical space needed
      mapDiv.attributionControl.setPosition('bottomleft');

      const pin = L.marker([0, 0]).addTo(mapDiv);

      const LatLongBox = L.Control.extend({
        options: {
          position: 'topright'
        },
        onAdd: (map) => {
          const container = L.DomUtil.get('rainfall-data-form');
          map.on('click', async (e) => {
            if (container.contains(e.originalEvent.target)) {
              //console.debug("Skipped click on control window", e);
              return;
            }

            const data = {
              latitude: e.latlng.lat.toFixed(2),
              longitude: e.latlng.lng.toFixed(2),
              stormDuration: this.stormDuration,
              returnPeriod: this.returnPeriod,
              climateFactor: this.selectedClimateChange,
            };

            const response = await fetch(`${BASE_URL}/rainfall/findRainStats/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Accept': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const ret = await response.json()

            this.updateRainfall(ret);
            pin.setLatLng(ret.latlng);
          });
          return container;
        }
      });
      mapDiv.addControl(new LatLongBox());
    },
    async onSettingChange() {
      //Haven't selected a location yet if there is no location index
      if (!this.locationIndex) return;
      //Avoid making a request for an impossible situation, the user has probably just cleared the input box
      if (this.stormDuration <= 0 || this.returnPeriod <= 0) return;

      const data = {
          r: this.r,
          M5: this.M5,
          LocationIndex: this.locationIndex,
          stormDuration: this.stormDuration,
          returnPeriod: this.returnPeriod,
          climateFactor: this.selectedClimateChange,
      };

      const response = await fetch(`${BASE_URL}/rainfall/updateRainStats/`, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data),
      });
      const ret = await response.json();

      this.updateRainfall(ret);
    },
  },
  watch: {
  }
}
</script>

<style scoped>
#mapContainer {
  background-color: white;
  width: min(1800px, 80vw);
  height: clamp(600px, 85vh, 800px);
  border-radius: 5px;
  position: absolute;
  box-shadow: 2px 2px 20px grey;
  display: flex;
  justify-content: center;
  top: 0;
  left: 10vw;
}

#rainfall-data-form {
  background-color: white;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 5px;
  box-shadow: 2px 2px 20px grey;
  width: 230px;
  font-size: 0.75rem;
}

h3 {
  margin: 0;
}

p {
  margin-bottom: 0;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0.5rem 0;
}

input {
  padding: 0;
  width: 30px;
  text-align: right;
}

#climate-change {
  width: 60px;
}

option {
  font-size: 0.8rem !important;
}

.r {
  margin: 0 1.5rem 0 0;
}

.ri {
  margin: 0;
}

.M5-60 {
  margin: 0;
}

.header {
  margin-top: 0;
}

.actions {
  float: right;
}

button {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
  margin-left: 1rem;
}

.ri-p {
  margin-top: 0.1rem;
}
</style>
