<template>
  <Dialog :header="$t('error')" :style="{width: '600px'}"
          :visible="displayErrorDialog" @update:visible="$emit('closeErrorDialog')">
    <p>{{ errorMessage }}</p>
    <template #footer>
      <Button :label="$t('ok')" icon="pi pi-check" autofocus @click="$emit('closeErrorDialog')"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  props: ['displayErrorDialog', 'errorMessage']
}
</script>

<style scoped>
</style>
