/**
 * @typedef RainfallUnit A unit of displaying rainfall intensity
 * @property {string} stored The database name of the unit
 * @property {string} name The displayed name of the unit
 * @property {number} scale The factor to multiply by to convert to mm/h
 * @property {(function(number): number)} convert Function to convert a mm/h value to the unit
 */
/** @this RainfallUnit */
const convert = function convert(value_mm_h) {
	return this.scale * value_mm_h;
}

/** @type RainfallUnit */
export const mm_h = {
	stored: 'mm/h',
	name: 'mm/h',
	scale: 1,
	convert,
};
/** @type RainfallUnit */
export const l_s_m2 = {
	stored: 'l/s/m<sup>2</sup>',
	name: 'l/s/m²', //Avoid needing to v-html things
	scale: 0.000278,
	convert,
};
/** @type RainfallUnit */
export const l_s_ha = {
	stored: 'l/s/ha',
	name: 'l/(s*ha)',
	scale: 2.78,
	convert,
}

/** @type {Map<string, RainfallUnit>} */
export const rainfall = new Map([mm_h, l_s_m2, l_s_ha].map(unit => [unit.stored, unit]));
