<template>
  <Dropdown optionLabel="name" panelClass="systemDropdown" scrollHeight="300px">
    <template #option="{option}"><div :class="[option.range]">{{ option.name }}</div></template>
  </Dropdown>
</template>

<script>
export default {
  name: "ChannelGroupSelector",
}
</script>

<style lang="scss">
@use "src/assets/scss/materials";

@mixin product-group($hover-colour) {
  font-family: "Interstate Light", "Trebuchet MS", Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;

  &:hover {
    /*color: white;
    background-color: $hover-colour;*/
  }
}

.systemDropdown {
  & .p-dropdown-items .p-dropdown-item {
    padding: 0 !important;

    & div {
      padding: 0.5rem 1rem;
    }

    & .civils {
      @include product-group(#009ede);
    }

    & .landscaping {
      @include product-group(#93c11c);
    }

    & .concrete {
      @include product-group(materials.$concrete-pavement);
    }
  }
}
</style>