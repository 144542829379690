<template>
  <div class="tooltip">
    <i class="pi pi-info-circle">
      <span class="tooltiptext" :style="textStyle"><slot>{{ text }}</slot></span>
    </i>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: ['text', 'textStyle'],
}
</script>

<style scoped lang="scss">
.tooltip {
  position: relative;
  display: inline-flex;

  & .tooltiptext {
    visibility: hidden;
    background-color: #5e5c5c;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    margin-left: 0.5rem;
    font-family: "Interstate Light", "Trebuchet MS", Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 22px;
    width: 200px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    margin-top: -50px;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}

.pi-info-circle {
  color: var(--blue);
}
</style>