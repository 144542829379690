<template>
  <navbar/>
<!--  <main-wrapper :isHauratonUser="isHauratonUser"-->
<!--                @duplicate-run="handleDuplicateRun($event)"-->
<!--  />-->

  <router-view></router-view>
  <div id="cookies" v-if="showCookieNotice">
    <div>
      {{ $t('cookies') }}
      <Button :label="$t('ok')" icon="pi pi-check" class="p-button-sm" @click="closeCookieNotice"/>
    </div>
  </div>
</template>

<script>

// import MainWrapper from './components/MainWrapper.vue'
import Navbar from './components/Navbar.vue'


export default {
  name: 'App',
  components: {
    // MainWrapper,
    Navbar
  },
  data() {
    return {
      showCookieNotice: false,
    };
  },
  beforeMount() {
    //Default the language to what the user's browser is set to use
    this.$i18n.locale = window.navigator.language;
    const cookie_notice = ('; ' + document.cookie).split('; cookie_notice=').pop().split(';')[0];
    this.showCookieNotice = cookie_notice !== '0';
  },
  methods: {
    closeCookieNotice() {
      const today = new Date();
      const expireDate = new Date(today.setDate(today.getDate() + 30)).toUTCString();
      document.cookie = `cookie_notice=0;path=/;expires=${expireDate};SameSite=Lax;`;
      this.showCookieNotice = false;
    },
  },
  watch: {
  },
}
</script>

<style>
@import url('./assets/css/hauraton.css');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
}

</style>

<style lang="scss" scoped>
#cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f2f3;

  > div {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 0.5em;
  }
}
</style>
