<template>
  <canvas ref="canvas" :width="canvasWidth_px" :height="canvasHeight_px" id="canvas"></canvas>
</template>

<script>
import {fabric} from "fabric";
import {mapGetters} from "vuex";

/** The width the channel body will be draw with */
const channelLength_px = 400;
/** The offset between the top of the canvas and the top of a left wall hole */
const topMarkerY = 0;
/** The length of lines left and right wall holes have */
const markerHeight_px = 30;
/** The offset between the top of the canvas and the top edge of the channel body */
const channelTop_px = 30;
/** The offset between the left of the canvas and the left edge of the channel body */
const channelLeft_px = 30;
/** The offset between the left of the canvas and the channel width text */
const widthLabelPosition = 0;

export default {
  name: "DrillingAtLastElement",
  props: ['drillingRadios', 'lastChannel', 'hasDrillingAtLast'],
  data() {
    return {
      /** Total available width to draw on */
      canvasWidth_px: 500,
      /** Total available height to draw on */
      canvasHeight_px: 250,
    }
  },
  methods: {
    eraseCanvas() {
      this.canvas.getObjects().map(o => this.canvas.remove(o))
    },
    drawBottomAxial(distance) {
      const hole = new fabric.Circle({
        id: 'bottom-axial-hole',
        radius: this.diameterOfDrilling_px,
        fill: 'transparent',
        left: channelLeft_px + channelLength_px - this.diameterOfDrilling_px - distance,
        top: (channelTop_px + (this.channelWidth_px / 2) - this.diameterOfDrilling_px),
        stroke: this.stroke,
        selectable: false
      })
      this.canvas.add(hole)
    },

    drawChannel() {
      const channel = new fabric.Rect({
        left: channelLeft_px,
        top: channelTop_px,
        fill: 'transparent',
        stroke: this.stroke,
        width: channelLength_px,
        height: this.channelWidth_px,
        selectable: false,
        id: 'channel'
      })


      // LENGTH LABEL
      const lengthLabel = new fabric.Text(`${this.lastChannel.channel_length_mm}`, {
        id: 'label',
        top: channelTop_px + this.channelWidth_px + 50,
        left: this.canvasWidth_px / 2,
        fontSize: 13,
        selectable: false,
        fontStyle: 'sans-serif',
        stroke: this.stroke,
      })

      // left arrow
      const lengthLeftArrowTop = new fabric.Line([
        channelLeft_px, // x1,
        channelTop_px + this.channelWidth_px + 70, // y1
        channelLeft_px + 5, // x2,
        channelTop_px + this.channelWidth_px + 65, // y2
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      const lengthLeftArrowBottom = new fabric.Line([
        channelLeft_px, // x1,
        channelTop_px + this.channelWidth_px + 70, // y1
        channelLeft_px + 5, // x2,
        channelTop_px + this.channelWidth_px + 75, // y2
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      // right arrow
      const lengthRightArrowTop = new fabric.Line([
        channelLeft_px + channelLength_px, // x1,
        channelTop_px + this.channelWidth_px + 70, // y1
        channelLeft_px + channelLength_px - 5, // x2,
        channelTop_px + this.channelWidth_px + 65, // y2
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      const lengthRightArrowBottom = new fabric.Line([
        channelLeft_px + channelLength_px, // x1,
        channelTop_px + this.channelWidth_px + 70, // y1
        channelLeft_px + channelLength_px - 5, // x2,
        channelTop_px + this.channelWidth_px + 75, // y2
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      const lengthLabelLine = new fabric.Line([
        channelLeft_px, // x1
        channelTop_px + this.channelWidth_px + 70, // y1
        channelLength_px + channelLeft_px, // x2
        channelTop_px + this.channelWidth_px + 70], {stroke: this.stroke, selectable: false, id: 'channel'}) // y2

      // WIDTH LABEL
      const widthLabel = new fabric.Text(`${this.lastChannel.outer_width_mm}`, {
        id: 'channel',
        top: channelTop_px + (this.channelWidth_px / 2) + 10,
        left: widthLabelPosition,
        fontSize: 13,
        selectable: false,
        angle: 270,
        fontStyle: 'sans-serif',
        stroke: this.stroke,
      })

      const widthLabelLine = new fabric.Line([
        widthLabelPosition + 17,
        channelTop_px,
        widthLabelPosition + 17,
        channelTop_px + this.channelWidth_px
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      const topArrowLeft = new fabric.Line([
        widthLabelPosition + 17,
        channelTop_px,
        widthLabelPosition + 12,
        channelTop_px + 5
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      const topArrowRight = new fabric.Line([
        widthLabelPosition + 17,
        channelTop_px,
        widthLabelPosition + 23,
        channelTop_px + 5
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      const bottomArrowLeft = new fabric.Line([
        widthLabelPosition + 17,
        channelTop_px + this.channelWidth_px,
        widthLabelPosition + 12,
        channelTop_px + this.channelWidth_px - 5
      ], {stroke: this.stroke, selectable: false, id: 'channel'})

      const bottomArrowRight = new fabric.Line([
        widthLabelPosition + 17,
        channelTop_px + this.channelWidth_px,
        widthLabelPosition + 23,
        channelTop_px + +this.channelWidth_px - 5
      ], {stroke: this.stroke, selectable: false, id: 'channel'})


      this.canvas.add(lengthLeftArrowTop)
      this.canvas.add(lengthLeftArrowBottom)
      this.canvas.add(lengthRightArrowTop)
      this.canvas.add(lengthRightArrowBottom)
      this.canvas.add(widthLabel)
      this.canvas.add(lengthLabel)
      this.canvas.add(lengthLabelLine)
      this.canvas.add(widthLabelLine)
      this.canvas.add(topArrowLeft)
      this.canvas.add(topArrowRight)
      this.canvas.add(bottomArrowLeft)
      this.canvas.add(bottomArrowRight)
      this.canvas.add(channel)
    },
    drawSideDrill(x, position) {
      const markerY = position === 'left' ? topMarkerY : (topMarkerY + this.channelWidth_px + markerHeight_px)
      const startMarker = new fabric.Line([x, markerY, x, markerY + markerHeight_px],
          {
            id: 'to-move',
            stroke: this.stroke,
            strokeWidth: 1,
            selectable: 0,
          }
      )
      const endMarkerX = x + (this.diameterOfDrilling_px * 2);
      const endMarker = new fabric.Line([endMarkerX, markerY, endMarkerX, markerY + markerHeight_px],
          {
            id: 'right-drill-end',
            stroke: this.stroke,
            strokeWidth: 1,
            selectable: 0,
          }
      )
      const mid = markerY + markerHeight_px / 2;
      const leftArrowBody = new fabric.Line([
        x - 30,
        mid,
        (x - 30) + 30,
        mid], {
        id: 'right-drill-end',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: 0,
      })
      const leftArrowPointTop = new fabric.Line([
        x - 15,
        mid - 5,
        (x - 30) + 30,
        mid], {
        id: 'right-drill-end',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: 0,
      })
      const leftArrowPointBottom = new fabric.Line([
        x - 15,
        mid + 5,
        (x - 30) + 30,
        mid], {
        id: 'right-drill-end',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: 0,
      })
      const rightArrowBody = new fabric.Line([
        endMarkerX + 30,
        mid,
        (endMarkerX + 30) - 30,
        mid], {
        id: 'to-move',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: 0,
      })
      const rightArrowPointTop = new fabric.Line([
        endMarkerX + 15,
        mid - 5,
        (endMarkerX + 30) - 30,
        mid], {
        id: 'to-move',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: 0,
      })
      const rightArrowPointBottom = new fabric.Line([
        endMarkerX + 15,
        mid + 5,
        (endMarkerX + 30) - 30,
        mid], {
        id: 'to-move',
        stroke: this.stroke,
        strokeWidth: 1,
        selectable: 0,
      })
      const label = new fabric.Text(`${this.$store.getters.getDrillingDiameterValue}`, {
        id: 'label',
        top: mid,
        left: x + this.diameterOfDrilling_px,
        originX: 'center',
        originY: 'center',
        fontSize: 11,
        fontWeight: 'bold',
        selectable: false,
        fontFamily: 'sans-serif',
        fill: this.stroke,
        objectCaching: false, //Turning caching off has a drastic improvement to the blur centring can cause (fabric.js#4113)
      })
      this.eraseCanvas()
      this.drawChannel()
      this.canvas.add(label)
      this.canvas.add(leftArrowPointTop)
      this.canvas.add(leftArrowPointBottom)
      this.canvas.add(leftArrowBody)
      this.canvas.add(rightArrowPointTop)
      this.canvas.add(rightArrowPointBottom)
      this.canvas.add(rightArrowBody)
      this.canvas.add(startMarker)
      this.canvas.add(endMarker)

    },
    updateSideDrillingDiameter(position) {
      const allObjects = this.canvas.getObjects()
      const objectsToRemove = allObjects.filter(o => o.id !== 'channel')
      objectsToRemove.map(o => this.canvas.remove(o))
      this.drawSideDrill((channelLeft_px + channelLength_px) - this.distanceFromEdge_px - this.diameterOfDrilling_px, position)

      this.canvas.renderAll()
    },
  },
  computed: {
    ...mapGetters({
      diameterOfDrilling_mm: 'getDrillingDiameterValue',
      distanceFromEdge_mm: 'getDistanceEdgeToDrillingCenterValue',
      drillingPosition: 'getDrillingAtLastPosition',
    }),
    stroke() {
      return this.hasDrillingAtLast ? 'black' : 'lightgray'
    },
    scale() {
      const scale = channelLength_px / this.lastChannel.channel_length_mm;
      return Math.min(scale, 140 / this.channelWidth_mm);
    },
    channelWidth_px() {
      return this.lastChannel && this.channelWidth_mm * this.scale;
    },
    channelWidth_mm() {
      return this.lastChannel && this.lastChannel.outer_width_mm
    },
    diameterOfDrilling_px() {
      return this.lastChannel && (this.diameterOfDrilling_mm * this.scale) / 2;
    },
    distanceFromEdge_px() {
      return this.lastChannel && this.distanceFromEdge_mm * this.scale;
    },
  },
  watch: {
    stroke() {
      this.eraseCanvas()
      this.drawChannel()
      // Render a new one with updated diameter
      this.drawBottomAxial(this.distanceFromEdge_px)
    },
    distanceFromEdge_px(distanceFromEdge_px) {
      this.eraseCanvas();
      this.drawChannel();
      switch (this.drillingPosition) {
        case 'rightWall': {
          this.drawSideDrill((channelLeft_px + channelLength_px) - distanceFromEdge_px - this.diameterOfDrilling_px, 'right')
          break
        }
        case 'leftWall': {
          this.drawSideDrill((channelLeft_px + channelLength_px) - distanceFromEdge_px - this.diameterOfDrilling_px, 'left')
          break
        }
        case 'bottom':
          // Render a new one with updated diameter
          this.drawBottomAxial(distanceFromEdge_px)
          break
      }

    },
    diameterOfDrilling_px() {
      switch (this.drillingPosition) {
        case 'rightWall': {
          this.updateSideDrillingDiameter('right')
          break
        }
        case 'leftWall': { // // Remove end marker already drawn
          // this.canvas.remove(this.canvas.getObjects().find(o => o.id === 'to-move'))
          // Render a new one with updated diameter
          this.updateSideDrillingDiameter('left')
          break
        }
        case 'bottom':
          // Remove hole already drawn
          this.canvas.remove(this.canvas.getObjects().find(o => o.id === 'bottom-axial-hole'))
          // Render a new one with updated diameter
          this.drawBottomAxial(this.distanceFromEdge_px)
          break
      }
    },
    drillingPosition(drillingPosition) {
      this.eraseCanvas()
      this.drawChannel()
      switch (drillingPosition) {
        case 'rightWall': {
          this.drawSideDrill((channelLeft_px + channelLength_px) - this.distanceFromEdge_px - this.diameterOfDrilling_px, 'right')
          break
        }
        case 'leftWall': {
          this.drawSideDrill((channelLeft_px + channelLength_px) - this.distanceFromEdge_px - this.diameterOfDrilling_px, 'left')
          break
        }
        case 'bottom':
          this.drawBottomAxial(this.distanceFromEdge_px)
          break
      }
    },
    lastChannel() {
      this.eraseCanvas()
      this.drawChannel()
      switch (this.drillingPosition) {
        case 'rightWall': {
          this.drawSideDrill((channelLeft_px + channelLength_px) - this.distanceFromEdge_px - this.diameterOfDrilling_px, 'right')
          break
        }
        case 'leftWall': {
          this.drawSideDrill((channelLeft_px + channelLength_px) - this.distanceFromEdge_px - this.diameterOfDrilling_px, 'left')
          break
        }
        case 'bottom':
          this.drawBottomAxial(this.distanceFromEdge_px)
          break
      }
    },
  },
  mounted() {
    const ref = this.$refs.canvas;
    this.canvas = new fabric.Canvas(ref)
    this.eraseCanvas()
    this.drawChannel()
    switch (this.drillingPosition) {
      case 'rightWall': {
        this.drawSideDrill((channelLeft_px + channelLength_px) - this.distanceFromEdge_px - this.diameterOfDrilling_px, 'right')
        break
      }
      case 'leftWall': {
        this.drawSideDrill((channelLeft_px + channelLength_px) - this.distanceFromEdge_px - this.diameterOfDrilling_px, 'left')
        break
      }
      case 'bottom':
        this.drawBottomAxial(this.distanceFromEdge_px)
        break
    }
  }
}
</script>

<style scoped>
#canvas {
  /*border: 1px solid red*/
}
</style>
