<template>
  <Dialog :header="$t('longitudinalSlopeWarning.title')" style="max-width: 600px;" :closable="false"
          :visible="display" @update:visible="$emit('update:display', $event)">
    <p>{{ $t('longitudinalSlopeWarning.text', {limit: slopeLimit}) }}</p>
    <template #footer>
      <Button :label="$t('ok')" icon="pi pi-check" autofocus @click="$emit('update:display', false)"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'TooSlopyWarning',
  props: ['display'],
  emits: ['update:display'],
  computed: {
    slopeLimit() {
      return this.$store.getters.selectedRun.calculation_method === 'GVF' ? 10 : 30;
    },
  },
}
</script>

<style scoped>
</style>