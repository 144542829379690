<template>
  <Dropdown :model-value="selectedLoading" :options="availableLoadings" :placeholder="`Select ${$t('loading')}`"
            @update:model-value="$emit('update:selectedLoading', $event)">
    <template #value="label">{{ label.value ? `${$t('loading')}: ${label.value}` : label.placeholder }}</template>
    <template #option="label">
      <img class="loading-image" :alt="`Icon for ${label.option} loading factor`"
           :src="require(`@/assets/images/loading/${label.option.replace(' ', '')}.png`)">
      <div class="loading-label">{{ label.option }}</div>
    </template>
  </Dropdown>
</template>

<script>
export default {
  name: "LoadingSelector",
  props: ['selectedLoading', 'availableLoadings'],
  emits: ['update:selectedLoading'],
}
</script>

<style scoped>
.loading-image {
  height: 25px;
  vertical-align: bottom;
}

.loading-label {
  display: inline-block;
  padding: 0 0 2px 10px;
}
</style>