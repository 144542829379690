<template>
  <div class="wrapper">
    <div class="col left">
      <BlockUI :blocked="saved" class="card" :autoZIndex="false">
        <h1>{{ $t('register') }}</h1>
        <p class="register-description">{{ $t('createAnAccountParagraph') }}</p>

        <form action="#">
          <div class="registration-row">
            <div class="input long-input p-float-label">
              <InputText type="text" id="username" autocomplete="username" v-model="registerForm.username"
                         :class="{'p-invalid': errors.username}"
                         v-tooltip.top.focus="{disabled: !errors.username, value: errors.username?.join('\n')}"/>
              <label for="username">{{ $t('login.username') }}*</label>
            </div>
          </div>

          <div class="registration-row">
            <div class="input p-float-label">
              <InputText id="password" type="password" autocomplete="new-password" v-model="registerForm.password"
                         :class="{'p-invalid': errors.password}"
                         v-tooltip.top.focus="{disabled: !errors.password, value: errors.password?.join('\n')}"/>
              <label for="password">{{ $t('login.password') }}*</label>
            </div>
            <div class="input p-float-label">
              <InputText type="password" id="passwordConfirmation" autocomplete="new-password"
                         v-model="registerForm.passwordConfirmation" :class="{'p-invalid': errors.password}"
                         v-tooltip.top.focus="{disabled: !errors.password, value: errors.password?.join('\n')}"/>
              <label for="passwordConfirmation">{{ $t('passwordConfirmation') }}*</label>
            </div>
          </div>

          <div class="registration-row">
            <div class="input p-float-label">
              <InputText type="text" autocomplete="given-name" v-model="registerForm.firstName" id="firstName"
                         :class="{'p-invalid': errors.firstName}"
                         v-tooltip.top.focus="{disabled: !errors.firstName, value: errors.firstName?.join('\n')}"/>
              <label for="firstName">{{ $t('firstName') }}*</label>
            </div>
            <div class="input p-float-label">
              <InputText type="text" autocomplete="family-name" v-model="registerForm.lastName" id="lastName"
                         :class="{'p-invalid': errors.lastName}"
                         v-tooltip.top.focus="{disabled: !errors.lastName, value: errors.lastName?.join('\n')}"/>
              <label for="lastName">{{ $t('lastName') }}*</label>
            </div>
          </div>

          <div class="registration-row">
            <div class="input long-input p-float-label">
              <InputText type="text" autocomplete="email" v-model="registerForm.email" id="email"
                         :class="{'p-invalid': errors.email}"
                         v-tooltip.top.focus="{disabled: !errors.email, value: errors.email?.join('\n')}"/>
              <label for="email">{{ $t('email') }}*</label>
            </div>
          </div>

          <div class="registration-row">
            <div class="input long-input p-float-label">
              <InputText type="text" v-model="registerForm.company" id="company"
                         :class="{'p-invalid': errors.company}"
                         v-tooltip.top.focus="{disabled: !errors.company, value: errors.company?.join('\n')}"/>
              <label for="company">{{ $t('company') }}*</label>
            </div>
          </div>

          <div class="registration-row">
            <div class="input long-input p-float-label">
              <InputText type="text" autocomplete="tel" v-model="registerForm.telephone" id="telephone"
                         :class="{'p-invalid': errors.telephone}"
                         v-tooltip.top.focus="{disabled: !errors.telephone, value: errors.telephone?.join('\n')}"/>
              <label for="telephone">{{ $t('telephone') }}</label>
            </div>
          </div>

          <div class="registration-row">
            <div class="input long-input p-float-label">
              <TextArea rows="3" v-model="registerForm.address" autocomplete="street-address" autoResize id="address"
                        :class="{'p-invalid': errors.address}"
                         v-tooltip.top.focus="{disabled: !errors.address, value: errors.address?.join('\n')}"/>
              <label for="address">{{ $t('address') }}*</label>
            </div>
          </div>
          <div class="registration-row">
            <div class="input p-float-label">
              <InputText type="text" autocomplete="address-level2" v-model="registerForm.city" id="city"
                         :class="{'p-invalid': errors.city}"
                         v-tooltip.top.focus="{disabled: !errors.city, value: errors.city?.join('\n')}"/>
              <label for="city">{{ $t('city') }}*</label>
            </div>
            <div class="input p-float-label">
              <InputText type="text" autocomplete="postal-code" v-model="registerForm.postcode" id="postcode"
                         :class="{'p-invalid': errors.postcode}"
                         v-tooltip.top.focus="{disabled: !errors.postcode, value: errors.postcode?.join('\n')}"/>
              <label for="postcode">{{ $t('postcode') }}*</label>
            </div>
          </div>
          <div class="registration-row">
            <div class="input long-input p-float-label"
                 v-tooltip.top="{disabled: !errors.country, value: errors.country?.join('\n')}">
              <CountryDropdown v-model:country="registerForm.country" id="country"
                               :class="{'p-invalid': errors.country}"/>
              <label for="country">{{ $t('country') }}*</label>
            </div>
          </div>

          <div class="registration-row last">
            <div class="captcha-container">captcha will go here</div>
            <Button :label="$t('register')" @click="register" :loading="loading"/>
          </div>

          <i18n-t scope="global" keypath="passwordReset.otherwiseLogIn" tag="div" class="row otherwise">
            <template #here>
              <router-link to="/">{{ $t('login.here') }}</router-link>
            </template>
          </i18n-t>

        </form>
        <div v-show="saved" class="card overlay">
          <h3>{{ $t('registrationSaved') }}</h3>
          <p>{{ $t('checkConfirmationEmail') }}</p>
          <router-link to="/" custom v-slot="{navigate}">
            <Button :label="$t('staffAdmin.back')" icon="pi pi-home" @click="navigate"
                    style="float: right;" class="p-button-sm"/>
          </router-link>
        </div>
      </BlockUI>
    </div>

    <div class="col">
      <div class="card feature">
        <img src="@/assets/images/registration-features/gears.svg" alt="gear" class="icon">
        <div class="feature-wrapper">
          <h2>{{ $t('designReassurance') }}</h2>
          <p>{{ $t('reassuranceParagraph') }}</p>
        </div>
      </div>
      <div class="card feature">
        <img src="@/assets/images/registration-features/confidential.svg" alt="shield" class="icon">
        <div class="feature-wrapper">
          <h2>{{ $t('confidential') }}</h2>
          <p>{{ $t('confidentialParagraph') }}</p>
        </div>
      </div>
      <div class="card feature">
        <img src="@/assets/images/registration-features/stacked_channels.svg" alt="stacked channel" class="icon">
        <div class="feature-wrapper">
          <h2>{{ $t('hydraulicDesign') }}</h2>
          <p>{{ $t('hydraulicDesignParagraph') }}</p>
        </div>
      </div>
      <div class="card feature">
        <img src="@/assets/images/registration-features/experience.svg" alt="Hauraton bulb" class="icon">
        <div class="feature-wrapper">
          <h2>{{ $t('experienceAndExpertise') }}</h2>
          <p>{{ $t('experienceParagraph') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import BlockUI from 'primevue/blockui';
import CountryDropdown from "@/components/main_content/tabs_content/CountryDropdown";

import {BASE_URL} from '@/constants';

export default {
  name: "RegistrationPage",
  components: {BlockUI, CountryDropdown},
  data() {
    return {
      countries: [],
      registerForm: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        telephone: '',
        country: '',
        address: '',
        city: '',
        postcode: '',
        password: '',
        passwordConfirmation: '',
      },
      loading: false,
      errors: {
      },
      saved: false,
    }
  },
  async mounted() {
    this.registerForm = this.$store.getters.getRegisterForm
  },
  methods: {
    async register() {
      if (this.registerForm.password !== this.registerForm.passwordConfirmation) {
        this.errors = {
          password: ['Passwords do not match'],
        };
        return;
      }
      this.loading = true;
      const response = await fetch(`${BASE_URL}/drainage/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          username: this.registerForm.username,
          password: this.registerForm.password,
          firstName: this.registerForm.firstName,
          lastName: this.registerForm.lastName,
          email: this.registerForm.email,
          company: this.registerForm.company,
          telephone: this.registerForm.telephone,
          address: this.registerForm.address,
          city: this.registerForm.city,
          postcode: this.registerForm.postcode,
          country: this.registerForm.country,
        }),
      });
      if (response.ok) {
        this.saved = true;
      } else if (response.status === 400) {
        const errors = await response.json();
        for (const [name, value] of Object.entries(errors)) {
          value.forEach((message, i) => {
            let replacement;
            switch (message) {
              case 'This field may not be blank.':
                replacement = 'Please enter ';
                break;
              case 'This field may not be null.':
                replacement = 'Please select ';
                break;
              case 'Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.':
                value[i] = message.replace('Enter a valid username. This value', 'A username');
                return;
              default:
                return; //Continue the forEach
            }
            switch (name) {
              case 'firstName':
                replacement += 'a first name';
                break;
              case 'lastName':
                replacement += 'a last name';
                break;
              case 'email':
              case 'address':
                replacement += `an ${name}`;
                break;
              default:
                replacement += `a ${name}`;
            }
            value[i] = replacement;
          });
        }
        this.errors = errors;
      } else {
        Sentry.captureMessage('Error registering user', {
          extra: {
            type: 'fetch',
            status: response.status,
            response: await response.text(),
            form: this.registerForm,
          },
        });
      }
      this.loading = false;
    },
  },
  watch: {
    registerForm: {
      deep: true,
      handler: function (newValue) {
        this.$store.commit('updateRegisterForm', newValue)
      }
    }
  }
}
</script>

<style scoped>

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card {
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 2px 2px 10px grey;
  margin: auto;
}

.col {
  display: flex;
  font-size: 12px;
  font-family: "Interstate Light", "Trebuchet MS", Verdana, Geneva, Arial, Helvetica, sans-serif;
  /*border: 1px solid red;*/
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.2rem 2rem;
}

.feature {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1.2rem;
  margin: 0;
}

h1, h2 {
  text-transform: uppercase;
}

h2 {
  margin-top: 0.1rem;
}

.p-blockui-container ::v-deep(.p-blockui) {
  background-color: rgba(0, 0, 0, 0.6) !important; /* Default is 40% opacity, makes it harder to read the overlay */
  border-radius: 5px;
}

.p-blockui-container .overlay {
  position: absolute;
  z-index: 5000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.left {
  width: 45%;
}

.registration-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  width: 200px;
}

.long-input {
  width: 420px
}

h1, h3 {
  text-align: center;
  margin-top: 0;
}

.p-float-label {
  margin-top: 20px;
}

.register-description {
}

.p-inputtext, .p-dropdown ::v-deep(.p-inputtext) {
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.p-float-label label {
  /* Fix the default PrimeVue positioning expecting 0.5rem of padding */
  margin-top: 0;
  transform: translateY(-50%);
}

::v-deep(.p-inputtext.p-component) {
  padding: 0.3rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.captcha-container {
  width: 302px;
  height: 76px;
  background-color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.last {
  margin-top: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}

.icon {
  height: 5rem;
}

form {
  width: 25rem;
}

span {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.2rem;
}

.otherwise {
  justify-content: flex-start;
  margin-top: 1rem;
  white-space: pre-wrap; /* Ensure the correct spacing between the text and link */
}

@media screen and (min-width: 1700px) {
  .col {
    margin-top: 1.8rem;
  }

  .feature {
    width: 60rem;
  }

  .left {
    justify-content: center;
    width: 35%;
  }

  form {
    width: 30rem;
  }
}


</style>
