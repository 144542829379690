<template>
  <div id="temp-navbar">
    <header>
      <router-link :to="{name: 'home'}" id="logo">DesignSoftware</router-link>
      <img src="@/../../drainage/static/images/logos/deschacht.png" alt="Deschacht Logo"
           v-if="inBelgium" class="jump-right" />
    </header>
  </div>
  <div id="links">
    <span @click="showWebLinks" class="blue">{{ $t('webLinks') }}</span>
    <Menu ref="webLinks" :model="webLinks" :popup="true" style="width: max-content;">
      <template #item="{item}">
        <a :href="item.url" target="_blank" class="web-link red" :title="item.label">{{item.label}}</a>
      </template>
    </Menu>
    <router-link :to="{name: 'home'}" v-if="$route.name === 'staff' || $route.name === 'shared-projects' || (isMarketing && $route.name === 'staff-channels')"
                 class="blue">{{ $t('staffAdmin.back') }}</router-link>
    <template v-else>
      <router-link :to="{name: 'shared-projects'}"
                   class="blue" v-show="canSeeSharedProjects">{{ $t('sharedProjects.button') }}</router-link>
      <router-link :to="{name: 'staff-channels'}" style="padding: 0 0.25rem"
                   class="blue" v-show="isMarketing">{{ $t('staffAdmin.channelAvailability') }}</router-link>
      <router-link :to="{name: 'staff'}" :target="$route.name?.startsWith('staff-') ? undefined : '_blank'"
                   class="blue" v-show="isStaff">{{ $t('staffAdmin.button') }}</router-link>
    </template>
    <span v-show="isUserAuthenticated" class="passive bold blue jump-right">{{ username }}</span>
    <span @click="showSettings = true" v-show="isUserAuthenticated" class="blue flex">
      <Cog/> <span>{{ $t('userSettings.button') }}</span>
    </span>
    <router-link to="/" custom v-slot="{ navigate }">
      <span @click="onLogout(navigate)" v-show="isUserAuthenticated" class="bold blue">{{ $t('logout') }}</span>
    </router-link>
    <router-link to="/help" target="_blank" class="bold blue flex" :class="{'jump-right': !isUserAuthenticated}">
      <QuestionMark/> <span>{{ $t('help.button') }}</span>
    </router-link>
  </div>
  <user-settings v-model:display="showSettings" v-if="isUserAuthenticated"/>
</template>

<script>
import {logout} from "@/auth";
import UserSettings from "@/components/main_content/UserSettings";
import Cog from '@/assets/images/settings.svg?inline';
import QuestionMark from '@/assets/images/help.svg?inline';
import {mapGetters, mapState} from "vuex";

export default {
  name: "Navbar",
  components: {Cog, QuestionMark, UserSettings},
  data() {
    return {
      showSettings: false,
    };
  },
  computed: {
    ...mapGetters([
        'isUserAuthenticated',
        'canSeeSharedProjects',
        'isMarketing',
        'isStaff',
    ]),
    ...mapState([
       'user',
    ]),
    webLinks() {
      if (this.isUserAuthenticated) {
        const {country} = this.user;

        switch (country.region) {
          case 'Asia':
            return [
              {label: 'HAURATON Asia', url: 'https://www.hauraton.asia'},
            ];

          case 'Australia':
            return [
              {label: 'HAURATON International (Australia)', url: 'https://www.hauraton.com/oz/'},
              {label: 'GRAF Plastics (Australia)', url: 'https://www.grafplasticsaustralia.com.au'},
            ];

          case 'AUSTRIA ':
            return [
              {label: 'HAURATON (Austria)', url: 'https://www.hauraton.com/de-at/'},
            ];

          case 'BULGARIA ':
            return [
              {label: 'HAURATON (Bulgaria)', url: 'https://www.hauraton.com/bg/'},
            ];

          case 'FRANCE ':
            return [
              {label: 'HAURATON (France)', url: 'https://www.hauraton.com/fr/'},
            ];

          case 'GERMANY ':
            return [
              {label: 'HAURATON (Germany)', url: 'https://www.hauraton.com/de/'},
            ];

          case 'HUNGARY ':
            return [
              {label: 'HAURATON (Hungary)', url: 'https://www.hauraton.com/hu/'},
            ];

          case 'ITALY ':
            return [
              {label: 'HAURATON (Italy)', url: 'https://www.hauraton.com/it/'},
            ];

          case 'NETHERLANDS ':
            return [
              {label: 'HAURATON (Netherlands)', url: 'https://www.hauraton.com/nl/'},
            ];

          case 'NZ':
            return [
              {label: 'HAURATON International (New Zealand)', url: 'https://www.hauraton.co.nz'},
              {label: 'Hygrade Water New Zealand', url: 'https://www.hygradewater.co.nz'},
            ];

           case 'POLAND ':
            return [
              {label: 'HAURATON (Poland)', url: 'https://www.hauraton.com/pl/'},
            ];

          case 'SWITZERLAND ':
            return [
              {label: 'HAURATON (Switzerland)', url: 'https://www.hauraton.com/de-ch/'},
            ];

          case 'UK':
            return [
              {label: 'HAURATON (UK)', url: 'https://www.hauraton.co.uk'},
            ];

          case 'Special':
            break; //Default is fine

          default:
            console.warn("Missed region", country.region); //New region?
          // noinspection FallThroughInSwitchStatementJS
          case 'EU':
            //Default for everywhere else, try the country itself
            switch (country.iso_code) {
              case 'BE':
                return [
                  {label: 'HAURATON International (Europe)', url: 'https://www.hauraton.eu'},
                  {label: 'DESCHACHT (Dutch)', url: 'https://www.deschacht.eu/engineering/lijnafwatering'},
                  {label: 'DESCHACHT (French)', url: 'https://www.deschacht.eu/fr/engineering/drainage-lineaire'},
                ];
            }
            break;
        }
      }

      //General default for anywhere (including not being logged in)
      return [
        {label: 'HAURATON International', url: 'https://www.hauraton.com'},
      ];
    },
    username() {
      return this.user?.username;
    },
    inBelgium() {
      return this.isUserAuthenticated && this.user.country.iso_code === 'BE';
    },
  },
  methods: {
    showWebLinks(event) {
      this.$refs.webLinks.toggle(event);
    },
    async onLogout(navigate) {
      await logout();
      this.$store.commit('resetUserAuthentication');
      navigate();
    }
  },
}
</script>

<style scoped lang="scss">
#temp-navbar {
  $side-padding: 33px; //The horizontal padding between the navbar and the edges of the window
  $logo-padding: 30px; //The offset of the Hauraton logo from the right side of the window
  margin-top: auto;
  background-image: url("~@/assets/HAURATON.svg");
  background-size: auto 55%;
  background-position: right $logo-padding center;
  background-repeat: no-repeat;
  background-color: #fff;
  font-size: 2em;
  //The width of the logo is 220px
  padding: 0 ($side-padding + 220px + $logo-padding) 0 $side-padding
}

header {
  height: 60px;
  display: flex;
  align-items: center;

  & > img {
    height: 100%;
  }
}

#links {
  height: 35px;
  display: flex;
  gap: .5em;
  background-color: #f0f2f3;
  font-size: 13px;
  line-height: 30px;
  position: relative;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.15);

  & > * {
    flex: 0 0 7.5rem;
    min-width: max-content;
    text-decoration: none;
    text-align: center;
    color: var(--blue);
    font-weight: 500;

    &.flex {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }

    &:not(.passive) {
      cursor: pointer;

      &:hover {
        color: var(--light);
        font-weight: bolder;
      }
    }
  }

  & .bold {
    font-size: larger;
    font-weight: bolder;
  }
}

.jump-right {
  margin-left: auto;
}

$link-colours: (
  "red": var(--red),
  "orange": #ee7401,
  "green": #93c11c,
  "blue": var(--blue),
);

@each $name, $colour in $link-colours {
  #links .#{$name} {
    border-top: 5px solid $colour;

    &:hover:not(.passive) {
      background-color: $colour;
    }

    &.bold.passive, &.bold:not(:hover) {
      color: $colour;
    }
  }
}

.web-link {
  display: inline-block;
  padding: 10px 5px;
  width: 100%; //Ensure shorter links still highlight all the way
  color: #4b4b4b;
  text-decoration: none;

  &:hover {
    color: #313131;
    font-weight: bold;
  }

  @each $name, $colour in $link-colours {
    &.#{$name} {
      border-left: 5px solid $colour;

      &:hover {
        background-color: $colour;
      }
    }
  }

  &::after {
    //Nasty hack to keep the menu wide enough for the text to be bold without jumping/wrapping
    //Idea is to have invisible bold text always present to force the menu container wider
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
}

#logo {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

@media (max-width: 1900px) {
  #temp-navbar {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
