<template>
  <div id="results-wrapper">
    <div id="design-specifications-wrapper">
      <design-specifications/>
      <img id="section-canvas" alt="Design cross sectional image"
           :src="`data:image/png;base64,${selectedRun.results.x_section_image}`"/>
      <span id="small_print"><br/>{{ $t('generic_image')}}</span>
    </div>
    <div id="right-side-wrapper">
      <h4 id="hydraulic-profile-title">{{ $t('hydraulicProfile') }}</h4>
      <div id="notes" v-if="getNotes.length > 0">
        <Button icon="pi pi-trash" class="p-button-sm p-button-text" @click="clearNotes"/>
        <p>{{ getNotes }}</p>
      </div>
      <hydraulic-profile id="hydraulic-profile-canvas" :selectedRun="selectedRun" :draw-short="true"/>
      <h4 id="drainage-area-title">{{ $t('drainageArea') }}</h4>
      <irregular-catchment v-if="selectedRun.catchmentShape === 'irregular'" :read-only="true"
                           :channelLength_m="getChannelLength" :catchmentArea_sqm="getDrainageArea"
      />
      <uniform-catchment v-else :currentPermeability="getPermeabilityName"
                         :channelLength_m="getChannelLength" :catchmentArea_sqm="getDrainageArea"
      />
    </div>
  </div>
</template>

<script>
import DesignSpecifications from "@/components/main_content/tabs_content/DesignSpecifications";
import HydraulicProfile from "@/components/main_content/tabs_content/ordinary_user_design_run/HydraulicProfile";
import IrregularCatchment from "@/components/main_content/tabs_content/design_run/catchment/IrregularCatchment";
import UniformCatchment from "@/components/main_content/tabs_content/design_run/catchment/UniformCatchment";
import {mapGetters} from "vuex";

export default {
  name: "Results",
  components: {
    DesignSpecifications,
    HydraulicProfile,
    IrregularCatchment,
    UniformCatchment,
  },
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'selectedRun',
      'getChannelLength',
      'getDrainageArea',
      'getPermeabilityName',
      'getNotes',
    ]),
  },
  methods: {
    clearNotes() {
      this.$store.commit('setNotes', '');
    },
  }
}
</script>

<style scoped>
span, input, label {
  font-size: 0.7rem;
  padding: 2px;
  border: none;
}

#results-wrapper {
  display: flex;
  flex-direction: row;
}

#design-specifications-wrapper {
  width: 30%;
}

#right-side-wrapper {
  width: 70%;
  padding-left: 2rem;
}

#section-canvas {
  object-fit: contain;
  margin: 1.5rem 1.0rem auto auto;
}

#hydraulic-profile-title {
  margin-top: 1rem;
}

#notes {
  display: flex;
  align-items: center;
}

p {
  font-size: 0.7rem;
  flex-grow: 1;
  margin: 0;
  z-index: 1;
}

#hydraulic-profile-canvas {
  height: auto;
  width: 800px;
  margin: 0 auto;
}

#hydraulic-profile-title + #hydraulic-profile-canvas {
  margin-top: 3rem;
}

#drainage-area-title {
  margin-top: 1rem;
}
</style>
