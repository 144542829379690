import MainWrapper from "../components/MainWrapper";
import HelpPage from "../components/main_content/HelpPage";
import RegistrationPage from "@/components/main_content/tabs_content/RegistrationPage";
import PasswordResetPage from '@/components/PasswordResetPage';
import LoadSharedProject from "@/components/main_content/LoadSharedProject";
import {createRouter, createWebHistory} from 'vue-router';


const routes = [
    {path: '/:shareID([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?', component: MainWrapper, name: 'home'},
    {path: '/help', component: HelpPage},
    {path: '/sign-up', component: RegistrationPage},
    {path: '/password-reset', component: PasswordResetPage, props: {userID: null}, name: 'bad-password-reset'},
    {path: '/password-reset/:userID', component: PasswordResetPage, props: true},
    {path: '/projects', component: LoadSharedProject, name: 'shared-projects'},
    {path: '/projects/:supportID(\\d+)', component: MainWrapper, name: 'shared-project'},
    {path: '/staff', component: () => import(/* webpackChunkName: "admin" */'@/components/admin/AdminPage'), children: [
        {path: '', component: () => import(/* webpackChunkName: "admin" */'@/components/admin/AdminUsers'), name: 'staff'},
        {path: 'user/:userID', component: () => import(/* webpackChunkName: "admin" */'@/components/admin/UserProjectsList'), props: true, name: 'staff-projects'},
        {path: 'user/:userID/:superID(\\d+)', component: MainWrapper, name: 'staff-project-view'},
        {path: 'channels', component: () => import(/* webpackChunkName: "admin" */'@/components/admin/AdminChannelTable'), name: 'staff-channels', meta: {allowMarketing: true}},
        {path: 'map', component: () => import(/* webpackChunkName: "admin-map" */'@/components/admin/AdminMap'), name: 'staff-map'},
        {path: 'translate', component: () => import(/* webpackChunkName: "admin-trans" */'@/components/admin/HelpTranslator'), name: 'translations'},
    ]},
];
//
const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});


export default router;
