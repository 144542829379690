<template>
  <div class="main-wrapper">
    <!--  LEFT SIDE  -->
    <div class="left-wrapper">
      <DesignParametersBox :isAdvanced="true"/>
    </div>
    <!--   RIGHT SIDE -->
    <div class="right-wrapper">
      <irregular-catchment v-if="selectedRun.catchmentShape=== 'irregular'"
                           :channelLength_m="channelLength" :catchmentArea_sqm="catchmentArea"
      />
      <uniform-catchment v-else-if="channelLength !== 0  && catchmentArea !==0"
                         :currentPermeability="this.getPermeabilityName"
                         :channelLength_m="channelLength"
                         :catchmentArea_sqm="catchmentArea"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import IrregularCatchment from "./IrregularCatchment";
import UniformCatchment from "./UniformCatchment";
import DesignParametersBox from "@/components/main_content/DesignParametersBox";


export default {
  name: "Catchment",
  components: {
    DesignParametersBox,
    UniformCatchment,
    IrregularCatchment,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'selectedRun',
      'getPermeabilityName',
    ]),
    catchmentArea: {
      get() {
        return this.selectedRun.drainage_area_m2;
      },
      set(value) {
        this.$store.commit('setCatchmentArea', {catchmentArea: value});
      }
    },
    channelLength: {
      get() {
        return this.selectedRun.channel_length_m;
      },
      set(value) {
        this.$store.commit('setChannelLength', {channelLength: value});
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.right-wrapper {
  width: 70%;
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.left-wrapper {
  width: 20%;
}

#point-catchment-inputs {
  margin-top: 1rem;
}

@media (max-width: 1900px) {
  .left-wrapper {
    width: auto;
  }

  .right-wrapper {
    margin-left: 3rem;
  }
}

</style>
