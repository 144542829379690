<template>
  <Dialog :header="$t('accessorySwitchLoss.title')" style="max-width: 600px;"
          :visible="display" @update:visible="$emit('update:display', $event)">
    <p>{{ $t('accessorySwitchLoss.text') }}</p>
    <template #footer>
      <Button :label="$t('ok')" icon="pi pi-check" autofocus @click="$emit('update:display', false)"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'ResetAccessoriesWarning',
  props: ['display'],
  emits: ['update:display'],
}
</script>

<style scoped>
</style>