<template>
  <Dialog :header="$t('rainfall.default.title')" :visible="displayDefaultRainfallDialog" :style="{width: '25vw'}">
    <p>{{ $t('rainfall.default.changeDefault') }}</p>
    <template #footer>
      <Button :label="$t('rainfall.default.no')" icon="pi pi-times" class="p-button-text"
              @click="$emit('closeDefaultRainfallDialog')"/>
      <Button :label="$t('rainfall.default.yes')" icon="pi pi-check" @click="handleYes" autofocus/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "DefaultRainfallDialog",
  props: ['displayDefaultRainfallDialog'],
  data() {
    return {
      defaults: this.$store.getters.getRainfallDefaults
    }
  },
  computed: {
    currentData() {
      return this.$store.getters.getRainfallData
    }
  },
  methods: {
    handleYes() {
      // Updates defaults to be used in other runs
      this.$store.commit('setRainfallDefaults', this.currentData)
      this.$emit('closeDefaultRainfallDialog')
    },
  }
}
</script>

<style scoped>
</style>
