export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engleski"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Njemački"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francuski"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mađarski"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talijanski"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrvatski"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opterećenje"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa projekta - odaberite projekt za učitavanje"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izradio"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podijelite projekt"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Da biste omogućili pristup ovom proračunu projekta, kopirajte i zalijepite vezu u nastavku za dijeljenje s kolegama ili klijentima:\n\n", _interpolate(_named("link")), "\n\nOstale strane će se trebati registrirati za pristup istoj verziji softvera za hidraulički dizajn."])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiraj vezu"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izbriši projekt"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema spremljenih projekata"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prikaz ", "{", "first", "}", " do ", "{", "last", "}", " od ", "{", "totalRecords", "}", " projekata"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Učitano 0 pokretanja od ", _interpolate(_named("total"))]), _normalize(["Učitano 1 izvođenje od ", _interpolate(_named("total"))]), _normalize(["Učitano ", _interpolate(_named("count")), " pokretanja od ", _interpolate(_named("total"))])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitavanje projekta nije uspjelo"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremiti"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremiti projekt"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podijelite projekt s HAURATON-om"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON može biti u mogućnosti dati vrijedan input / savjet prilikom provjere dizajna i / ili može pomoći u postizanju najisplativije konfiguracije kanala kroz optimizaciju."])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako je potreban hitan odgovor nakon dijeljenja projekta, kontaktirajte HAURATON:"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sljedeća pokretanja zahtijevaju izračun prije spremanja: ", _interpolate(_named("runs"))])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt treba ime"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija je istekla, pokušajte se prijaviti s drugom karticom/prozorom i ponovno spremite"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremanje projekta nije uspjelo..."])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Slika presjeka je generička i koristi se samo za ilustraciju"])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv projekta"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt "])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis projekta"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulacija"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izračunati"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija je istekla, pokušajte se prijaviti s drugom karticom/prozorom, a zatim ponovno izračunajte"])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj liniju kanala"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj novi kanal trenutnom dizajnu"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj bilješku"])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj bilješke kanalu koji je u fazi projektiranja. Napomene će se nalaziti u tablicama s hidrauličkim podacima."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetiraj"])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisnik može vratiti projektirani kanal i prozor parametara dizajna na zadane postavke."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopija"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napravite kopiju trenutnog dizajna kanala"])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sliv"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanali"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultat"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizajn"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis materijala"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenti"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sustav odvodnje"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veličina kanala"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrđena duljina"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duljina"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visina"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne."])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobodan odljev"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odljev putem pjeskolova"])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odaberite pjeskolov (dijeli se s ", _interpolate(_named("shared")), ")"])},
    "chooseShared": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odaberite pjeskolov (dijeli se s ", _interpolate(_named("shared")), ")"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pjeskolov s ispustom ", _interpolate(_named("discharge")), " mm"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Podijeli s ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nema dostupnih pjeskolova ", _interpolate(_named("loading")), "+ za kanale ", _interpolate(_named("type"))])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifikacija na elementu pražnjenja"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isključi vrste s ugrađenim padom"])},
  "definitionOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogled na posljednji element bušenja"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element bušenja na pražnjenju"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promjer bušenja"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promjer rupe koju treba izbušiti u kanalu"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udaljenost: kraj kanala do središta bušenja"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donji aksijalni"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijevi bočni zid"])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desni bočni zid"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element za rezanje na pražnjenju"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duljina od kraja"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kut rezanja"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri dizajna"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija kanala / točka odvoda koji se projektira."])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duljina kanala"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenzija duljine kanala. Duljina kanala trebala bi biti veća od 1 m. Ne uključuje dimenzije modularne kutije/komore na početku i kraju kanala."])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Širina slivnog područja"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udaljenost slivnog područja proteže se sa svake duže strane kanala."])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slivna površina"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupna površina (m²) koja je potrebna za odvod kanala."])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepravilni oblik slivnog područja"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slivno područje "])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Uniformno' - slivno područje pravokutnog oblika s istom propusnošću.\n'Nepravilno' - slivno područje koje se sastoji od potencijalno višestrukih trapeza, s neovisnom propusnošću.\nPogledajte Projektiranje nepravilnih slivnih područja u datoteci POMOĆI."])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepravilnni oblik"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniformno"])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi oblik slivnog područja"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otvori"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekoračeno je maksimalno područje sliva"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slivno područje mora biti manje od 100 000 m², molimo smanjite svoj dizajn za najmanje ", _interpolate(_named("excess")), " m²"])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracija"])},
  "configurationTT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kaskadni' - Kanal se izvodi s nizom dubljih kanalskih jedinica ugrađenih prema izlazu.", _interpolate(_list(0)), "'Ravni' - kanali iste širine i dubine međusobno povezani kako bi formirali kanal.", _interpolate(_list(0)), "Za daljnje informacije pogledajte datoteku POMOĆI."])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaskadno"])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilješka"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekti betonskih kanala ne mogu biti kaskadni"])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizajni čeličnih kanala ne mogu biti stepenasti"])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta padalina"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite na ikonu za kartu oborina u Velikoj Britaniji. Odaberite točne podatke o oborinama za određene lokacije dodatnim klikom na Kartu oborina u Velikoj Britaniji. Vrijednosti intenziteta oborina mogu se unijeti i ručno."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intenzitet padalina"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite na tekst 'mm/hr' da biste promijenili mjerenje padalina u litre/sekundu/hektar' (l/(s*ha)) ili 'litre/sekundu/m²' (l/s/m²)."])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trajanje oborine"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavi zadanu količinu padalina"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Želite li koristiti odabranu količinu oborina kao zadanu za nova ispitivanja?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda dizajna"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manning"])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istraživanje hidraulike Wallingford"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["HAURATON DesignSoftware pruža korisniku mogućnost odabira između dvije hidrauličke formule za izračun veličina kanala:", _interpolate(_list(0)), "Modified Manning-Strickler (Manning)", _interpolate(_list(0)), "Hydraulics Research Wallingford (HRW).", _interpolate(_list(0)), "Za daljnje informacije pogledajte datoteku 'Pomoć'."])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavljanje nagiba"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagib"])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadmorska visina"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'Nagib' – Uključite nagib nivelete kao postotnu vrijednost", _interpolate(_list(0)), "'Nadmorska visina' – Nagib tla izračunat korištenjem vertikalne promjene visine u razinama tla na početku i kraju kanala."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzdužni nagib"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To se odnosi na uzdužni površinski nagib terena uz koji je položen kanal (ne na nagib slivnog područja koje se slijeva prema kanalu). Ako je tlo ravno, unesite 0%."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekoračen je maksimalni kut nagiba"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Najveći dopušteni nagib za koji hidraulička teorija vrijedi je 1 u ", _interpolate(_named("limit"))])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promjena visine"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vertikalna visina tla na krajevima niza koja se, zajedno s duljinom kanala, može koristiti za izračunavanje uzdužnog nagiba tla", _interpolate(_list(0)), "N = Vrijednost elevacije na početku (početku) niza", _interpolate(_list(0)), "I = Vrijednost elevacije na ispustu niza"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naslov"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ispust"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimalni omjer promjene visine je premašen"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Najveći dopušteni nagib za koji vrijedi hidraulička teorija je 1 prema 30.", _interpolate(_list(0)), "Smanjite promjenu visine dizajna ili povećajte njegovu dužinu"])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrh kanala je ispod ispusta"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["DS3.0 je dizajniran za kanale ugrađene duž ravnog tla ili blagih pozitivnih uzdužnih nagiba.", _interpolate(_list(0)), "Pazite da promjena visine ne rezultira negativnim nagibom"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamijenite visine"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Važna informacija"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 je dizajniran uz pretpostavku postupno varirajućeg protoka unutar linearnih drenažnih sustava ugrađenih duž ravnog tla ili s blagim uzdužnim nagibima."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za kanale postavljene duž strmijih padina, projektant bi trebao uzeti u obzir da protok vode unutar sustava kanala može varirati između subkritičnih i superkritičnih uvjeta. To može dovesti do većih turbulencija unutar tijela kanala i mogućeg lokalnog površinskog plavljenja."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za projekte sa strmijim uzdužnim nagibima tla, DS3.0 će osigurati dizajn kanala s dovoljnim kapacitetom za prilagodbu izračunatom protoku, ali ne uzima u obzir brzo promjenjiv protok koji se može pojaviti unutar tijela kanala s posljedicama."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za takve posebne situacije savjetujemo da projektanti koriste odgovarajuću metodologiju i formulu za predviđanje i prilagođavanje superkritičnih uvjeta protoka."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne prikazuj ovo više"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolirano pražnjenje"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolirano pražnjenje"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["HICAP utor kanal može se koristiti za skladištenje vode i prigušivanje.", _interpolate(_list(0)), "Projektant može kontrolirati brzinu pražnjenja iz kanala.", _interpolate(_list(0)), "Vrijednost će prikazati '0' ako protok nije ograničen", _interpolate(_list(0)), "Pogledajte 'Kontrolirano pražnjenje' u datoteci POMOĆI."])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizajni koji koriste kontrolirano pražnjenje ne mogu biti stepenasti ili u nagibu"])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besplatno pražnjenje"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brzina kojom će voda izaći iz kanala ako nije ograničena, na temelju intenziteta oborina i površine sliva"])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetiraj"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetirajte pražnjenje da bude slobodno"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izračunati"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izračunajte rezultat granice"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primijeniti"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koristite kontrolirani protok pražnjenja za trenutni rad"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nijedan kanal nije mogao biti dizajniran da ispuni trenutne parametre"])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odabrani kanal"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritično trajanje"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promjer otvora"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritične oborine"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupni volumen kanala"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna ispunjenost"])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna veličina kanala"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna veličina kanala za korištenje za trenutno izvođenje"])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekoračena je najveća preporučena duljina kanala"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimalna preporučena duljina kanala je 500 m, razmislite o smanjenju dizajna za najmanje ", _interpolate(_named("excess")), " m"])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premašena je najveća duljina kanala"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimalna duljina kanala za ", _interpolate(_named("method")), " dizajn je ", _interpolate(_named("limit")), "m, smanjite svoj dizajn za najmanje ", _interpolate(_named("excess")), "m"])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podijeliti"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spojiti"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za pojedinosti o podjeli / spajanju drenažnih područja, pogledajte datoteku POMOĆI (Projektiranje slivnih područja nepravilnih oblika)"])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj područja"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duljina"])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna dubine"])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Završna dubine"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Površina"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propusnost"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duljina područja"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijevo područje"])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desno područje"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostrano"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifikacija dizajna"])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odabrani sustav"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazivna širina kanala"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unutarnja širina kanala"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unutarnja dubina kanala"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrapavost"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta kanala"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invertiraj dubinu"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupna dubina"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitavanje"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brzina protoka"])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pražnjenje kanala"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekomjerna stopa poplave (približno)"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protok pražnjenja"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iskorišteni volumen"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupni volumen sustava"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobodni bok"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugrađeni gradijent"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako je trasa kanala projektirana sa 'stepenastom' konfiguracijom, razlika u visinama s obzirom na uključene jedinice kanala doprinijet će 'ugrađenom gradijentu' trase kanala. U takvim će slučajevima 'ugrađeni gradijent' biti veći od 'uzdužnog nagiba'"])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidraulički profil"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odvodno područje"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odabir sustava"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prebacite se na napredno"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijeđi na standardno"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite sustav"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite faktor propusnosti"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktor nepropusnosti (koeficijent otjecanja) - Ova se vrijednost odnosi na propusnost i strukturu površinskog materijala tla i odredit će volumen vode koja dospijeva u sustav kanala nakon što teče preko površine slivnog područja."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betonski pločnik"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Površina sa smolastim premazima"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opločnici - cementne fuge"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asfalt"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opločnici - punjeni pijeskom"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šljunak"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meki krajolik"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prilagođen"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokacija"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvrtka"])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektant"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šifra artikla"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Količina"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvezi jezik"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvod"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi uvod"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hidraulička analiza za ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis materijala"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi popis materijala"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi informacije"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svi dokumenti"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmi sve dokumente"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Završni poklopac s izlazom"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite završni poklopac"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("material")), " ", _interpolate(_named("diameter")), " mm izlaz"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Za kanal ", _interpolate(_named("channel")), " nema dostupnih završnih otvora"])}
  },
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promijeni stranu"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdi"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otkaži"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihvati"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obriši"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilješke"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duljina cijevi"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duljina cijevi koja izlazi iz odvodne/izlazne kutije"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promjer cijevi"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promjer cijevi koja izlazi iz odvodne / izlazne kutije"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagib cijevi"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kut pod kojim cijev izlazi iz odvoda je u odnosu na tlo"])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijelo produžetka"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektant može dodati produžni dio u točkasti odvod pomoću preklopnog gumba"])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Povucite/ispustite (lijevi klik) crveni krug da biste dodali točku dotoka u kanal.", _interpolate(_list(0)), "Povucite/ispustite plavu strelicu za dodavanje pribora u kanal.", _interpolate(_list(0)), "Desni klik na crveni krug/plavu strelicu za brisanje iz hidrauličkog profila"])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Točka dotoka"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprema"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udaljenost od pražnjenja"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubina"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rešetka"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberite rešetku"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenzije utora"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otvoreno područje"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dužina rešetke"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pažnja"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postojao je barem jedan dovod/pribor postavljen izvan novonavedene duljine kanala, stoga su svi uklonjeni. Dodajte natrag po želji."])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pažnja"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaci su uklonjeni kako bi se omogućilo prebacivanje na napredni način rada. Dodajte natrag po želji."])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji priljeva"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji pribora"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položaj"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvor"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izravna cijev"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protok"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Način povezivanja"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neovisno"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutija za smeće"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristupna kutija"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristupni poklopac"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čep"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Završni poklopac s izlazom"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betonska komora"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozorenje"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema dodataka kompatibilnih s odabranom pozicijom."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo kontaktirajte HAURATON za više informacija ili pomoć."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udaljenost od pražnjenja je prevelika"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web veze"])},
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator osoblja"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazad"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Činjenica dana: ", _interpolate(_named("fact"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pretraživanje"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Očisti"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisničko ime"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime i prezime"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvrtka"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica"])},
    "hasAdvancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napredni način rada"])},
    "isStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoblje"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremi"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prikaz ", "{", "first", "}", " do ", "{", "last", "}", " od ", "{", "totalRecords", "}", " korisnika"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotok vode"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ova funkcija omogućuje uključivanje dodatnog točkastog dotoka unutar točkastog odvoda. Dodajte volumen točkastog dotoka u litrama/sekundi (l/s)."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvo odaberite kanal."])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odabrani kanali ne odgovaraju navedenoj dužini."])},
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavke"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavke korisnika"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jezik"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadani način rada"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardno"])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napredno"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadane jedinice intenziteta kiše"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt jedinice intenziteta oborina"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIJAVITI SE"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako već imate račun na ovoj stranici, prijavite se ovdje:"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisničko ime"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijaviti se"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zaboravili ste lozinku? Kliknite ", _interpolate(_named("here")), " da biste ju ponovno postavili."])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nemate račun? Registrirajte se ", _interpolate(_named("here")), "."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovdje"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovno postavljanje lozinke"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U nastavku unesite svoju adresu e-pošte i poslat ćemo vam poveznicu koju možete koristiti za ponovno postavljanje lozinke."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovno postavljanje lozinke"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inače se možete prijaviti ", _interpolate(_named("here")), "."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova lozinka:"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdi novu lozinku:"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promjeni lozinku:"])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veza za poništavanje zaporke bila je nevažeća, vjerojatno zato što je već korištena."])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatražite ponovno postavljanje lozinke ako je i dalje potrebno."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZNAČAJKE SOFTVERA"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzija UK"])},
    "asia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzija Azija"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzija Europa/Internacionalna"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povratne informacije"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako želite dati povratne informacije o našem softveru za dizajn, pogledajte sljedeće:"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa & Svijet"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ujedinjeno Kraljevstvo"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKTIRAJTE NAS ZA SPECIJALIZIRANU PODRŠKU U DIZAJNU"])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLOBODA DIZAJNA, BESPLATNO KORIŠTENJE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naša nova web-bazirana aplikacija 'DesignSoftware' pruža profesionalcima u industriji brzu, jednostavnu hidrauličku analizu, dimenzioniranje kanala, dizajn projekta i specifikaciju proizvoda za HAURATON asortiman sustava površinske odvodnje dok rade na vlastitim stolnim računalima, prijenosnim računalima i tabletima."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samo tri klika do izračuna..."])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriraj se"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako prvi put koristite ovu stranicu, otvorite račun u nastavku:"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezime"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrda lozinke"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica i broj"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grad"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštanski broj"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija uspješno spremljena"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provjerite svoju e-poštu za vezu za potvrdu"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiguranje dizajna"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DesignSoftware je opsežno testiran, a metodologija je potvrđena od strane neovisne konzultantske tvrtke za hidrauliku. Tehničko osoblje HAURATON-a već više od 30 godina uspješno koristi naš 'in-house' softver za projektiranje hidraulike uz potpunu pouzdanost."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povjerljivo"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrirani korisnici (zaštićeni lozinkom) imaju pristup sigurnoj i zaštićenoj pohrani na poslužitelju u vezi s njihovim konačnim dizajnom. Dizajni ostaju strogo povjerljivi osim ako korisnici ne označe \"podijeli s HAURATON-om\". Registrirani korisnici imaju opciju podijeliti poveznicu na spremljene dizajne kako bi ih mogli vidjeti drugi registrirani kolege ili kupci. DesignSoftware je konfiguriran za potpunu usklađenost s GDPR propisima o 'privatnosti podataka'."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIDRAULIČKI PROJEKT - DODATNI SUSTAVI"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za hidraulički dizajn dodatnih HAURATON sustava koji nisu uključeni u naš DesignSoftware, obratite se svojoj HAURATON podružnici ili lokalnom trgovinskom partneru za tehničku podršku."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iskustvo i stručnost"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svjetski lider u proizvodnji modularnih sustava površinske odvodnje, HAURATON proizvodi za odvodnju isporučuju se na velikim projektima na međunarodnim tržištima više od šezdeset pet godina. Naš prvi linijski odvodni sustav razvili smo 1956. Od tada je robna marka HAURATON postala poznata diljem svijeta kao mjerilo kvalitete, pouzdanosti, trajnosti i usluge."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomoć"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teme POMOĆI"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video vodiči"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O HAURATON DesignSoftware-u"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda dizajna"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcije trake izbornika"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje projektima"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o projektu"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizajn funkcije"])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozor parametara dizajna"])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektiranje nepravilnih slivnih područja"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta i podaci o oborinama (UK verzija)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Točkasti dotoci"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj pribor"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristupne točke"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijeđite na napredne dizajne"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozor specifikacije dizajna"])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o sustavu"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYFIX HICAP kanal utora"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugradnja sustava"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrška za dizajn"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopusti poplavu"])}
    }
  }
}