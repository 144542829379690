<template>
  <div id="project-load">
    <ProgressBar :value="progress">
      {{ $t('load.progressBar', {total}, done.size) }}
    </ProgressBar>
  </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";

export default {
  name: "ProjectLoadBar",
  components: {ProgressBar},
  props: ['total', 'done'],
  computed: {
    progress() {
      let runsDone = 0;

      for (let i = 1, end = this.total + 1; i < end; i++) {
        if (this.done.has(i)) runsDone++;
      }

      return 100 * runsDone / this.total;
    },
  },
}
</script>

<style scoped>
#project-load {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /*background-color: rgba(0, 0, 0, 0.25);*/
  pointer-events: none;
}
</style>