<template>
  <div id="results-wrapper">
    <design-parameters-box/>
    <div id="design-specifications-wrapper">
      <design-specifications/>
    </div>
    <div>
    <img id="section-canvas" :src="`data:image/png;base64,${selectedRun.results.x_section_image}`"
         alt="Design cross sectional image"/>
      <div id="small_print"><br/>{{ $t('generic_image')}}</div>

    </div>
    <div id="notes" v-show="notes.length > 0">
      <p>{{ notes }}</p><Button icon="pi pi-trash" class="p-button-sm p-button-text" @click="clearNotes"/>
    </div>
    <div class="hydraulic-profile-container" v-if="selectedRun.selectedSystem?.product_type !== 'P'">
      <div class="arrow-labels-wrapper">
        <i18n-t scope="global" keypath="arrowsDescription" tag="p" class="arrow-p">
          <br/>
        </i18n-t>
      </div>
      <hydraulic-profile :selectedRun="selectedRun"/>
    </div>
  </div>
</template>

<script>


import {mapGetters} from "vuex";
import HydraulicProfile from "./HydraulicProfile";
import DesignParametersBox from "../../DesignParametersBox";
import DesignSpecifications from "@/components/main_content/tabs_content/DesignSpecifications";

export default {
  name: "OrdinaryUserDesignRun",
  components: {
    DesignSpecifications,
    DesignParametersBox, HydraulicProfile
  },
  methods: {
    clearNotes() {
      this.$store.commit('setNotes', '');
    },
  },
  computed: {
    ...mapGetters([
      'selectedRun',
    ]),
    ...mapGetters({
      notes: 'getNotes',
    }),
  },
}
</script>

<style scoped lang="scss">
.arrow-p {
  margin-top: -2rem;
  font-size: 0.8rem;
  text-align: left;
}

.arrow-p br {
  margin-bottom: 0.25rem;
}

.arrow-labels-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 13rem
}

#notes {
  width: 100%;
  margin-right: 50px;
  display: flex;
  align-items: center;
}

p {
  font-size: 0.7rem;
  flex-grow: 1;
  margin: 0;
  text-align: end;
  z-index: 1;
}

#results-wrapper {
  display: flex;
  //padding-left: 2rem;
  flex-direction: row;
  margin-right: 9rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

#design-specifications-wrapper {
  width: 30rem;
}

#section-canvas {
  object-fit: contain;
}
#small_print{
  font-size: 0.6em;
}
#hydraulic-profile-title {
  margin-top: 2rem;
}

#hydraulic-profile-canvas {
  border: 1px solid blue;
  height: 200px;
  width: 1000px;
}

#drainage-area-title {
  margin-top: 1rem;
}

.drainage-area-canvas {
  border: 1px solid gray;
  height: 150px;
  width: 800px;
  margin: 2rem auto;
}

#hydraulic-profile-wrapper {
  float: right;
  margin-right: 9rem;
}

.hydraulic-profile-container {
  //align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 auto;
}

#hyrdo-profile-skeleton {
  margin-left: 4rem;
  margin-bottom: 6rem;
}

#xsection-profile-skeleton {
  margin-right: 3rem;
}

@media (max-width: 1900px) {
  #design-specifications-wrapper {
    width: 27%
  }

  #results-wrapper {
    margin-right: 0;
  }

  .hydraulic-profile-container {
  }

  .arrow-p {
    width: 12rem;
  }

  #section-canvas {
    width: 400px;
  }
}

</style>
